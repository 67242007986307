import React, { useEffect, useState, useCallback  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';

import { adminListAllOrders } from '../actions';
import { numberFormat } from '../utils/numberFormatter';

import { Message, Loader, TrackingDialog } from '../components';

import {
  Link,
  makeStyles,
  TextField,
  Toolbar,
  Card,
  CardContent,
  Grid,
  Typography,
  Collapse,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  SvgIcon,
  withStyles,
  InputAdornment,
  IconButton,
  Box,
  Tooltip,
  TablePagination
} from '@material-ui/core';
import {
  ArrowUpward,
  ArrowDownward,
  Language,
  Clear,
  Person,
  FiberManualRecord,
  LocalShipping,
  AssignmentReturned,
  AssignmentTurnedIn,
  // HighlightOff,
} from '@material-ui/icons';
import { ReactComponent as CASvg } from '../assets/images/ca.svg';
import { ReactComponent as USSvg } from '../assets/images/us.svg';

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  header: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  collapsedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    // cursor: 'pointer',
    // userSelect: 'none',
  },
  sortedColumn: {
    backgroundColor: '#f5f5f5',
  },
  column: {},
  sortIcon: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '0.25rem',
  },
  expandIcon: {
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  textLink: {
    color: theme.palette.text.link,
    cursor: 'pointer',
  },
}));

const StyledFormControlLabel = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})(FormControlLabel);

const CardedOrderList = ({ classes }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;
  const adminListOrders = useSelector((state) => state.adminListOrders);
  const { orders: adminOrders, count: count, loading } = adminListOrders;

  const [sortedField, setSortedField] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(0);
  const [dealerFilter, setDealerFilter] = useState('');
  const [currencyFilter, setCurrencyFilter] = useState('');

  const [expandedCardId, setExpandedCardId] = useState(null);
  const [limit, setlimit] = useState(100);
  // const [shipped, setShipped] = useState(false);
  const [status, setStatus] = useState('');
  const itemsPerPage = 100;
  useEffect(() => {
    if (userInfo?.isAdmin) {
      dispatch(adminListAllOrders(limit, itemsPerPage*(currentPage),  status, dealerFilter))
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(adminListAllOrders(limit, itemsPerPage*(newPage),  status, dealerFilter))
    setCurrentPage(newPage)
  }
  const handleStatusChange = ( newStatus) => {
    dispatch(adminListAllOrders(limit, itemsPerPage*(currentPage),  newStatus, dealerFilter))
    setStatus(newStatus)
  }
  const fetchFilteredData = useCallback(
    debounce((filterValue, status) => {
      dispatch(adminListAllOrders(limit, itemsPerPage*(currentPage),  status, filterValue))
    }, 800),
    []
  );
  const handleFilterChange = ( filter) => {
    fetchFilteredData(filter, status);
    setDealerFilter(filter)
  }

  const handleExpandClick = (id) => {
    setExpandedCardId((prevId) => (prevId === id ? null : id));
  };

  const sortOrders = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedField(field);
      setSortDirection('asc');
    }
  };

  const getField = (obj, path) =>
    path.split('.').reduce((o, p) => (o || {})[p], obj);

  // const orderList = adminOrders
  //   ?.filter((order) => {
  //     if (shipped) {
  //       return order.status === 'Shipped';
  //     } else {
  //       return order.status !== 'Shipped';
  //     }
  //   })
  //   .filter((order) => {
  //     return order.status !== 'Draft';
  //   });

  let ordersFiltered;
  if (adminOrders && Array.isArray(adminOrders)) {
    let ordersIn = adminOrders;
    if (currencyFilter) {
      ordersIn = ordersIn.filter(
        (order) => order.userDetails.bc_customer_priceGroup === currencyFilter
      );
    }
    ordersIn.sort((a, b) => {
      let result;
      const aValue = getField(a, sortedField);
      const bValue = getField(b, sortedField);
      if (aValue > bValue) result = 1;
      if (aValue < bValue) result = -1;
      if (sortDirection === 'desc') result *= -1;
      return result;
    });
    ordersFiltered = ordersIn;
  } else {
    ordersFiltered = adminOrders;
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message severity='error'>{error}</Message>
  ) : (
    <>
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='currency'
            name='currency'
            value={currencyFilter}
            onChange={(e) => setCurrencyFilter(e.target.value)}
          >
            <Tooltip arrow title='USD' placement='top'>
              <StyledFormControlLabel
                value='USD'
                control={<Radio size='small' />}
                label={
                  <SvgIcon
                    component={USSvg}
                    viewBox='0 0 640 480'
                    fontSize='small'
                    style={{ marginTop: 5 }}
                  />
                }
              />
            </Tooltip>
            <Tooltip arrow title='CAD' placement='top'>
              <StyledFormControlLabel
                value='CAD'
                control={<Radio size='small' />}
                label={
                  <SvgIcon
                    component={CASvg}
                    viewBox='0 0 640 480'
                    fontSize='small'
                    style={{ marginTop: 5 }}
                  />
                }
              />
            </Tooltip>
            <Tooltip arrow title='All' placement='top'>
              <StyledFormControlLabel
                value=''
                control={<Radio size='small' />}
                label={<Language fontSize='small' style={{ marginTop: 5 }} />}
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='status'
            name='status'
            value={status}
            onChange={(e) => handleStatusChange(e.target.value)}
          >
            <Tooltip arrow title='Submitted' placement='top'>
              <StyledFormControlLabel
                value='Submitted'
                control={<Radio size='small' />}
                label={
                  <AssignmentReturned
                    // component={USSvg}
                    // viewBox='0 0 640 480'
                    fontSize='small'
                    style={{ marginTop: 5 }}
                  />
                }
              />
            </Tooltip>
            <Tooltip arrow title='Approved' placement='top'>
              <StyledFormControlLabel
                value='Approved'
                control={<Radio size='small' />}
                label={
                  <AssignmentTurnedIn
                    // component={CASvg}
                    // viewBox='0 0 640 480'
                    fontSize='small'
                    style={{ marginTop: 5 }}
                  />
                }
              />
            </Tooltip>
            <Tooltip arrow title='Submitted & Approved' placement='top'>
              <StyledFormControlLabel
                value=''
                control={<Radio size='small' />}
                label={
                  <Box display='inline-flex'>
                    <AssignmentReturned
                      fontSize='small'
                      style={{ marginTop: 5 }}
                    />
                    <AssignmentTurnedIn
                      fontSize='small'
                      style={{ marginTop: 5 }}
                    />
                  </Box>
                }
              />
            </Tooltip>
            <Tooltip arrow title='Shipped' placement='top'>
              <StyledFormControlLabel
                value='Shipped'
                control={<Radio size='small' />}
                label={
                  <LocalShipping fontSize='small' style={{ marginTop: 5 }} />
                }
              />
            </Tooltip>
          </RadioGroup>
        </FormControl>
        <TextField
          label='Filter by Dealer'
          value={dealerFilter}
          onChange={(e) => handleFilterChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {dealerFilter && (
                  <IconButton
                    aria-label='clear filter'
                    edge='end'
                    onClick={() => handleFilterChange("")}
                    size='small'
                  >
                    <Clear fontSize='small' color='error' />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: 5 }}
          size='small'
        />
      </Toolbar>
      <Card style={{ marginBottom: 5 }}>
        <CardContent>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'createdAt'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('createdAt')}
            >
              Created
              {sortedField === 'createdAt' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'updatedAt'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('updatedAt')}
            >
              Updated
              {sortedField === 'updatedAt' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              className={
                sortedField === 'userDetails.bc_customer_displayName'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('userDetails.bc_customer_displayName')}
              // style={{ paddingLeft: 10 }}
            >
              Dealer / User
              {sortedField === 'userDetails.bc_customer_displayName' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'orderName'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('orderName')}
            >
              Order Name
              {sortedField === 'orderName' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              className={
                sortedField === 'status' ? classes.sortedHeader : classes.header
              }
              onClick={() => sortOrders('status')}
            >
              Status
              {sortedField === 'status' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className={
                sortedField === 'totalPrice'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('totalPrice')}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              Total
              {sortedField === 'totalPrice' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {ordersFiltered && ordersFiltered.length > 0 ? (
        ordersFiltered.map((order) => (
          <Card
            key={order._id}
            style={{ marginBottom: 5, cursor: 'pointer' }}
            onClick={() => handleExpandClick(order._id)}
          >
            <CardContent>
              <Grid container direction='row' spacing={1}>
                <Grid item xs={12} sm={2}>
                  {format(new Date(order.createdAt), 'MMM dd, yyyy')}
                </Grid>
                <Grid item xs={12} sm={2}>
                  {format(new Date(order.updatedAt), 'MMM dd, yyyy')}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: 5,
                    }}
                  >
                    {order.userDetails.bc_customer_priceGroup === 'USD' ? (
                      <SvgIcon
                        component={USSvg}
                        viewBox='0 0 640 480'
                        style={{ width: 20, height: 20, marginRight: 5 }}
                      />
                    ) : (
                      <SvgIcon
                        component={CASvg}
                        viewBox='0 0 640 480'
                        style={{ width: 20, height: 20, marginRight: 5 }}
                      />
                    )}
                    {order.userDetails.bc_customer_displayName}
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Person
                      color='primary'
                      style={{ width: 20, height: 20, marginRight: 5 }}
                    />{' '}
                    {order.userDetails.userName}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Link
                    onClick={(e) => e.stopPropagation()}
                    className={classes.textLink}
                    component={RouterLink}
                    underline='always'
                    to={{
                      pathname: '/store',
                      search: `order=${order._id}`,
                    }}
                  >
                    {order.orderName.length > 50
                      ? order.orderName.substring(0, 50)
                      : order.orderName}
                  </Link>
                </Grid>
                <Grid item xs={12} sm={1}>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    {order.status}{' '}
                    {order?.status === 'Approved' &&
                      order?.quoteId?.length === 0 && (
                        <TrackingDialog order={order} />
                      )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {`${numberFormat('cur-display', order.totalPrice)} ${
                    order.userDetails.bc_customer_priceGroup
                  }`}
                </Grid>
              </Grid>
              <Collapse
                onClick={(e) => e.stopPropagation()}
                key={order._id}
                in={expandedCardId === order._id}
                timeout='auto'
                unmountOnExit
              >
                <Grid
                  container
                  direction='row'
                  style={{ marginTop: 20, cursor: 'default' }}
                >
                  <Grid item xs={12} sm={3} className={classes.collapsedHeader}>
                    Details
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.collapsedHeader}>
                    Screens
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.collapsedHeader}>
                    Roofs
                  </Grid>
                  <Grid item xs={12} sm={3} className={classes.collapsedHeader}>
                    Parts
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction='row'
                  style={{ marginTop: 5, cursor: 'default' }}
                >
                  <Grid item xs={12} sm={3}>
                    <Box
                      display='flex'
                      flexDirection='row'
                      alignItems='baseline'
                    >
                      <Typography variant='subtitle2'>Sales Order #:</Typography>
                      <Box style={{ marginLeft: 5 }}>
                        <Typography variant='body2'>
                          {order.orderNumber ? order.orderNumber : 'N/A'}
                        </Typography>
                      </Box>
                    </Box>
                    {status === 'Shipped' && (
                      <Box display='flex' flexDirection='column'>
                        <Typography variant='subtitle2'>Shipping</Typography>
                        <Box
                          display='flex'
                          flexDirection='row'
                          alignItems='baseline'
                          style={{ marginLeft: 5 }}
                        >
                          <Typography variant='subtitle2'>Agent:</Typography>
                          <Box style={{ marginLeft: 5 }}>
                            <Typography variant='body2'>
                              {order.shippingAgent
                                ? order.shippingAgent
                                : 'N/A'}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          flexDirection='row'
                          alignItems='baseline'
                          style={{ marginLeft: 5 }}
                        >
                          <Typography variant='subtitle2'>Tracking:</Typography>
                          <Box style={{ marginLeft: 5 }}>
                            <Typography variant='body2'>
                              {order.trackingNumber
                                ? order.trackingNumber
                                : 'N/A'}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid container item xs={12} sm={3} direction='column'>
                    {order.screens.length > 0
                      ? order.screens?.map((screen) => (
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'baseline',
                            }}
                            key={screen._id}
                          >
                            <FiberManualRecord
                              style={{
                                width: 10,
                                height: 10,
                                marginRight: 5,
                              }}
                            />{' '}
                            {`${screen.screenName} - ${numberFormat(
                              'cur-display',
                              screen.price
                            )}`}
                          </Box>
                        ))
                      : 'No screens'}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {order.roofs.length > 0
                      ? order.roofs?.map((roof) => (
                          <Grid container item xs={12} sm={12} key={roof._id}>
                            <Box
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                              }}
                              key={roof._id}
                            >
                              <FiberManualRecord
                                style={{
                                  width: 10,
                                  height: 10,
                                  marginRight: 5,
                                }}
                              />{' '}
                              {`${roof.roofName} - ${numberFormat(
                                'cur-display',
                                roof.price
                              )}`}
                            </Box>
                          </Grid>
                        ))
                      : 'No roofs'}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    {order.parts.length > 0
                      ? order.parts?.map((part) => (
                          <Grid container item xs={12} sm={12} key={part._id}>
                            <Box
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                              }}
                              key={part._id}
                            >
                              <FiberManualRecord
                                style={{
                                  width: 10,
                                  height: 10,
                                  marginRight: 5,
                                }}
                              />{' '}
                              {`${part.displayName} - ${part.quantity}pc${
                                part.quantity > 1 ? 's' : ''
                              }`}
                            </Box>
                          </Grid>
                        ))
                      : 'No parts'}
                  </Grid>
                </Grid>
              </Collapse>
            </CardContent>
          </Card>
        ))
      ) : (
        <Card>
          <CardContent>
            <Typography variant='h6' align='center'>
              No orders to display.
            </Typography>
          </CardContent>
        </Card>
      )}
       <TablePagination
        component="div"
        count = {count ? count: 0}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={itemsPerPage}
        labelRowsPerPage=""
      />
    </>
  );
};

const OrderListAdmin = ({ shipped }) => {
  const classes = useStyles();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
  }, [history, userInfo]);

  return error ? (
    <Message severity='error'>{error}</Message>
  ) : (
    <CardedOrderList classes={classes} shipped={shipped} />
  );
};

export { OrderListAdmin };
