import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { login } from '../actions';

import { Message, Loader } from '../components';

import {
  makeStyles,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
  Button,
  Link,
  Avatar,
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const LoginView = ({ location, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [reset] = useState(location?.state?.reset);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const userRegister = useSelector((state) => state.userRegister);
  const { registerInfo } = userRegister;

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  useEffect(() => {
    if (userInfo && !isTokenExpired(userInfo?.token)) {
      history.push('/');
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <Container maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar style={{ margin: 1, backgroundColor: '#C69023' }}>
          <LockOutlined />
        </Avatar>
        <Typography variant='h5'>Sign in</Typography>
        {loading ? (
          <Loader />
        ) : (
          <>
            {error && <Message severity='error'>{error}</Message>}
            {registerInfo && (
              <Message severity='success'>
                Thanks for signing up, please login below.
              </Message>
            )}
            {reset && reset === 'complete' && (
              <Message severity='success'>
                Your password has been reset, please sign in below.
              </Message>
            )}
            <form className={classes.form} onSubmit={submitHandler}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link component={RouterLink} to='/reset'>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link component={RouterLink} to='/register'>
                    Don't have an account?
                  </Link>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>
    </Container>
  );
};

export { LoginView };
