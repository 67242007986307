// all docs
export const DOC_LIST_REQUEST = 'DOC_LIST_REQUEST';
export const DOC_LIST_SUCCESS = 'DOC_LIST_SUCCESS';
export const DOC_LIST_FAIL = 'DOC_LIST_FAIL';

// download doc
export const DOC_DOWNLOAD_REQUEST = 'DOC_DOWNLOAD_REQUEST';
export const DOC_DOWNLOAD_SUCCESS = 'DOC_DOWNLOAD_SUCCESS';
export const DOC_DOWNLOAD_FAIL = 'DOC_DOWNLOAD_FAIL';
export const DOC_ROOF_DOWNLOAD_REQUEST = 'DOC_ROOF_DOWNLOAD_REQUEST';
export const DOC_ROOF_DOWNLOAD_SUCCESS = 'DOC_ROOF_DOWNLOAD_SUCCESS';
export const DOC_ROOF_DOWNLOAD_FAIL = 'DOC_ROOF_DOWNLOAD_FAIL';

// upload doc
export const DOC_UPLOAD_REQUEST = 'DOC_UPLOAD_REQUEST';
export const DOC_UPLOAD_SUCCESS = 'DOC_UPLOAD_SUCCESS';
export const DOC_UPLOAD_FAIL = 'DOC_UPLOAD_FAIL';
