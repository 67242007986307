import React, {
  useContext,
  // useState
} from 'react';
// import { useDispatch } from 'react-redux';

// import { uploadDoc } from '../actions';

import { StoreContext } from '../context';
import { Loader, Message } from '../components';

import {
  FormControl,
  // FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  // withStyles,
  MenuItem,
  Select,
  // Switch,
  // Table,
  // TableBody,
  // TableRow,
  TextField,
  Typography,
  // RadioGroup,
  // Radio,
  Button,
  Box,
  Link,
} from '@material-ui/core';
// import MuiTableCell from '@material-ui/core/TableCell';

// import BaseRoof from '../assets/images/roof-example-placeholder.png';
import BaseRoof from '../assets/images/base.png';
import PerRoof from '../assets/images/per.png';
import ParRoof from '../assets/images/par.png';
import BothRoof from '../assets/images/both.png';
import { DeleteForever, AddBox } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: '99%',
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  splitCol: {
    display: 'flex',
  },
  screenSideIndicator: {
    maxWidth: '65%',
  },
  headerBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'rowReverse',
    gap: '16px',
  },
  titleBox: {
    flexGrow: 1,
  },
}));

// const TableCell = withStyles({
//   root: {
//     borderBottom: 'none',
//   },
// })(MuiTableCell);

let exampleImg = BaseRoof;

const DrawPosts = ({ rf }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);
  const { posts } = rf;

  const deletePostHandler = (e, i) => {
    e.preventDefault();
    const newPosts = [...posts];
    newPosts.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      posts: newPosts,
    });
  };

  return (
    posts?.length > 0 &&
    posts.map((post, i) => (
      <Box key={i} display='flex'>
        <FormControl className={classes.formControl} required>
          <InputLabel shrink>Post Style</InputLabel>
          <Select
            name='postStyle'
            required
            value={post.postStyle}
            onChange={(e) => ctx.handleChangePost(e, i)}
          >
            <MenuItem value={'Corner'}>Corner Saddle</MenuItem>
            <MenuItem value={'Flat'}>Flat Saddle</MenuItem>
            <MenuItem value={'Flush'}>Flush</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} required fullWidth>
          <InputLabel shrink>Post Length</InputLabel>
          <Select
            name='postLength'
            required
            value={post.postLength}
            onChange={(e) => ctx.handleChangePost(e, i)}
          >
            <MenuItem value={'10'}>10'</MenuItem>
            <MenuItem value={'12'}>12'</MenuItem>
            <MenuItem value={'20'}>20'</MenuItem>
          </Select>
        </FormControl>
        <Link
          onClick={(e) => deletePostHandler(e, i)}
          size='small'
          color='error'
          component='button'
          variant='body2'
        >
          <DeleteForever fontSize='small' />
        </Link>
      </Box>
    ))
  );
};
const DrawBeams = ({ rf }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);
  const { beams } = rf;

  const deleteBeamHandler = (e, i) => {
    e.preventDefault();
    const newBeams = [...beams];
    newBeams.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      beams: newBeams,
    });
    exampleImg = BaseRoof;
  };

  const setExampleImg = () => {
    // // console.log(val);
    let img = exampleImg;
    let par = ctx.rf.beams.find((b) => b.beamDir === 'Parallel');
    let per = ctx.rf.beams.find((b) => b.beamDir === 'Perpendicular');
    if (ctx.rf.beams.length > 0) {
      if (par && per) {
        img = BothRoof;
        // // console.log('parallel');
      } else if (par) {
        img = ParRoof;
        // // console.log('perpendicular');
      } else if (per) {
        img = PerRoof;
        // // console.log('both');
      }
      // // console.log(ctx.rf.beams);
    }
    // ctx.rf.beams.length > 0 && PerRoof;
    exampleImg = img;
    return exampleImg;
  };

  return (
    beams?.length > 0 &&
    beams.map((beam, i) => (
      <Box key={i} display='flex'>
        <FormControl className={classes.formControl} required>
          <InputLabel shrink>Beam Type</InputLabel>
          <Select
            name='beamType'
            required
            value={beam.beamType}
            onChange={(e) => ctx.handleChangeBeam(e, i)}
          >
            <MenuItem value={'Midspan'}>Midspan</MenuItem>
            <MenuItem value={'AccBeam'}>Accessory</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl} required fullWidth>
          <InputLabel shrink>Beam Direction</InputLabel>
          <Select
            name='beamDir'
            required
            value={beam.beamDir}
            onChange={(e) => {
              ctx.handleChangeBeam(e, i);
              setExampleImg();
            }}
          >
            <MenuItem value={'Parallel'}>Parallel</MenuItem>
            <MenuItem value={'Perpendicular'}>Perpendicular</MenuItem>
          </Select>
        </FormControl>
        <Link
          onClick={(e) => deleteBeamHandler(e, i)}
          size='small'
          color='error'
          component='button'
          variant='body2'
        >
          <DeleteForever fontSize='small' />
        </Link>
      </Box>
    ))
  );
};

const RoofForm = ({ order, status }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const ctx = useContext(StoreContext);

  const { beams } = order?.rf?.beams?.length > 0 ? order.rf : ctx.rf;
  const { posts } = order?.rf?.posts?.length > 0 ? order.rf : ctx.rf;

  const measurementDisplay = (num, fraction) => {
    return Number(fraction) === 0 ? `${num}"` : `${num} ${fraction}"`;
  };
  // const handleUploadDoc = (file) => {
  //   dispatch(uploadDoc(file));
  // };
  const addFileHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    // // console.log(formData.get('file'));
    ctx.setRf({
      ...ctx.rf,
      file: formData,
      fileName: formData.get('file').name,
    });
  };

  const clearFileHandler = (e) => {
    e.preventDefault();
    ctx.setRf({
      ...ctx.rf,
      file: null,
      fileName: '',
    });
    // document.getElementById('file').value = '';
  };

  return (
    <div className={classes.paper}>
      <form
        className={classes.form}
        onSubmit={(e) => ctx.handleAddRfToCart(e, ctx.rf)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color='inherit' variant='h6'>
              Louvered Roofs
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ paddingTop: '10px' }}>
              <strong>Roof Name</strong>
            </Typography>
            <FormControl required fullWidth style={{ marginBottom: '5px' }}>
              <TextField
                required
                type='text'
                value={ctx.rf.roofName}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  ctx.setRf({
                    ...ctx.rf,
                    roofName: e.target.value,
                  })
                }
              />
            </FormControl>
            <Typography style={{ paddingTop: '10px' }}>
              <strong>Measurements</strong>
            </Typography>
            <Typography variant='subtitle2'>X Outside Length</Typography>
            <Box display='flex'>
              <FormControl className={classes.formControl} required fullWidth>
                <TextField
                  required
                  value={ctx.rf.measurements.xLength}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    if (e.target.value.length < 4) {
                      ctx.setRf({
                        ...ctx.rf,
                        measurements: {
                          ...ctx.rf.measurements,
                          xLength: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 1,
                  }}
                  label='Inches'
                />
              </FormControl>
              <FormControl className={classes.formControl} required>
                <InputLabel shrink>Fraction</InputLabel>
                <Select
                  required
                  value={ctx.rf.measurements.xLengthFraction}
                  onChange={(e) =>
                    ctx.setRf(
                      {
                        ...ctx.rf,
                        measurements: {
                          ...ctx.rf.measurements,
                          xLengthFraction: e.target.value,
                        },
                      },
                      null,
                      e.target.value
                    )
                  }
                >
                  <MenuItem value={Number(0)}>0</MenuItem>
                  <MenuItem value={'1/16'}>1/16</MenuItem>
                  <MenuItem value={'1/8'}>1/8</MenuItem>
                  <MenuItem value={'3/16'}>3/16</MenuItem>
                  <MenuItem value={'1/4'}>1/4</MenuItem>
                  <MenuItem value={'5/16'}>5/16</MenuItem>
                  <MenuItem value={'3/8'}>3/8</MenuItem>
                  <MenuItem value={'7/16'}>7/16</MenuItem>
                  <MenuItem value={'1/2'}>1/2</MenuItem>
                  <MenuItem value={'9/16'}>9/16</MenuItem>
                  <MenuItem value={'5/8'}>5/8</MenuItem>
                  <MenuItem value={'11/16'}>11/16</MenuItem>
                  <MenuItem value={'3/4'}>3/4</MenuItem>
                  <MenuItem value={'13/16'}>13/16</MenuItem>
                  <MenuItem value={'7/8'}>7/8</MenuItem>
                  <MenuItem value={'15/16'}>15/16</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Typography variant='subtitle2' style={{ paddingTop: 5 }}>
              Y Outside Length
            </Typography>
            <Box display='flex'>
              <FormControl className={classes.formControl} required fullWidth>
                <TextField
                  required
                  value={ctx.rf.measurements.yLength}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    if (e.target.value.length < 4) {
                      ctx.setRf({
                        ...ctx.rf,
                        measurements: {
                          ...ctx.rf.measurements,
                          yLength: Number(e.target.value),
                        },
                      });
                    }
                  }}
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 1,
                  }}
                  label='Inches'
                />
              </FormControl>
              <FormControl className={classes.formControl} required>
                <InputLabel shrink>Fraction</InputLabel>
                <Select
                  required
                  value={ctx.rf.measurements.yLengthFraction}
                  onChange={(e) =>
                    ctx.setRf(
                      {
                        ...ctx.rf,
                        measurements: {
                          ...ctx.rf.measurements,
                          yLengthFraction: e.target.value,
                        },
                      },
                      null,
                      e.target.value
                    )
                  }
                >
                  <MenuItem value={Number(0)}>0</MenuItem>
                  <MenuItem value={'1/16'}>1/16</MenuItem>
                  <MenuItem value={'1/8'}>1/8</MenuItem>
                  <MenuItem value={'3/16'}>3/16</MenuItem>
                  <MenuItem value={'1/4'}>1/4</MenuItem>
                  <MenuItem value={'5/16'}>5/16</MenuItem>
                  <MenuItem value={'3/8'}>3/8</MenuItem>
                  <MenuItem value={'7/16'}>7/16</MenuItem>
                  <MenuItem value={'1/2'}>1/2</MenuItem>
                  <MenuItem value={'9/16'}>9/16</MenuItem>
                  <MenuItem value={'5/8'}>5/8</MenuItem>
                  <MenuItem value={'11/16'}>11/16</MenuItem>
                  <MenuItem value={'3/4'}>3/4</MenuItem>
                  <MenuItem value={'13/16'}>13/16</MenuItem>
                  <MenuItem value={'7/8'}>7/8</MenuItem>
                  <MenuItem value={'15/16'}>15/16</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Typography style={{ paddingTop: '10px' }}>
              <strong>Roof Example</strong>
            </Typography>
            <Box
              style={{
                // width: '100%',
                display: 'flex',
                // gap: '16px',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80%',
                marginBottom: '-30px',
              }}
            >
              <Box>
                <img src={exampleImg} alt='roof example' />
              </Box>
              <Box style={{ marginTop: '50px' }}>
                <Typography variant='subtitle2'>
                  {`x: ${measurementDisplay(
                    ctx.rf.measurements.xLength,
                    ctx.rf.measurements.xLengthFraction
                  )}`}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                // width: '100%',
                display: 'flex',
                // gap: '16px',
                justifyContent: 'center',
                // alignItems: 'center',
                marginRight: '100px',
              }}
            >
              <Typography variant='subtitle2'>
                {`y: ${measurementDisplay(
                  ctx.rf.measurements.yLength,
                  ctx.rf.measurements.yLengthFraction
                )}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={7}>
            <Typography style={{ paddingTop: '10px' }}>
              <strong>Colors</strong>
            </Typography>
            <Box display='flex'>
              <FormControl className={classes.formControl} required fullWidth>
                <InputLabel shrink>Frame Color</InputLabel>
                <Select
                  style={{ paddingTop: 5 }}
                  required
                  value={ctx.rf.colors.frameColor}
                  onChange={(e) =>
                    ctx.setRf({
                      ...ctx.rf,
                      colors: {
                        ...ctx.rf.colors,
                        frameColor: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={'White'}>White</MenuItem>
                  <MenuItem value={'Black'}>Black</MenuItem>
                  <MenuItem value={'Brown'}>Brown</MenuItem>
                  <MenuItem value={'Tan'}>Tan</MenuItem>
                  <MenuItem value={'Linen'}>Linen</MenuItem>
                  <MenuItem value={'Grey'}>Grey</MenuItem>
                  <MenuItem value={'MF'}>Custom *</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} required fullWidth>
                <InputLabel shrink>Blade Color</InputLabel>
                <Select
                  style={{ paddingTop: 5 }}
                  required
                  value={ctx.rf.colors.bladeColor}
                  onChange={(e) =>
                    ctx.setRf({
                      ...ctx.rf,
                      colors: {
                        ...ctx.rf.colors,
                        bladeColor: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={'White'}>White</MenuItem>
                  <MenuItem value={'Black'}>Black</MenuItem>
                  <MenuItem value={'Brown'}>Brown</MenuItem>
                  <MenuItem value={'Tan'}>Tan</MenuItem>
                  <MenuItem value={'Linen'}>Linen</MenuItem>
                  <MenuItem value={'Grey'}>Grey</MenuItem>
                  <MenuItem value={'MF'}>Custom *</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} required fullWidth>
                <InputLabel shrink>Post Color</InputLabel>
                <Select
                  style={{ paddingTop: 5 }}
                  required
                  value={ctx.rf.colors.postColor}
                  onChange={(e) =>
                    ctx.setRf({
                      ...ctx.rf,
                      colors: {
                        ...ctx.rf.colors,
                        postColor: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={'White'}>White</MenuItem>
                  <MenuItem value={'Black'}>Black</MenuItem>
                  <MenuItem value={'Brown'}>Brown</MenuItem>
                  <MenuItem value={'Tan'}>Tan</MenuItem>
                  <MenuItem value={'Linen'}>Linen</MenuItem>
                  <MenuItem value={'Grey'}>Grey</MenuItem>
                  <MenuItem value={'MF'}>Custom *</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {ctx.rf.colors.frameColor === 'MF' ||
            ctx.rf.colors.bladeColor === 'MF' ||
            ctx.rf.colors.postColor === 'MF' ? (
              <Box>
                <Typography color='error' variant='subtitle2'>
                  <em>* Indicate custom colors in Additional Details.</em>
                </Typography>
              </Box>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={5}>
            <Typography style={{ paddingTop: '10px' }}>
              <strong>Motors</strong>
            </Typography>
            <Box display='flex'>
              <FormControl className={classes.formControl} required fullWidth>
                <InputLabel shrink>Motor Type</InputLabel>
                <Select
                  required
                  style={{ paddingTop: 5 }}
                  value={ctx.rf.motorType}
                  onChange={(e) =>
                    ctx.setRf({
                      ...ctx.rf,
                      motorType: e.target.value,
                    })
                  }
                >
                  <MenuItem value={'Standard'}>Standard</MenuItem>
                  <MenuItem value={'Fast'}>Fast</MenuItem>
                  <MenuItem value={'Somfy'}>Somfy</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} required fullWidth>
                <TextField
                  style={{ paddingTop: 5 }}
                  required
                  value={ctx.rf.motorQuantity}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    if (e.target.value < 4) {
                      ctx.setRf({
                        ...ctx.rf,
                        motorQuantity: Number(e.target.value),
                      });
                    }
                  }}
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 1,
                  }}
                  label='Quantity'
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ paddingTop: '10px' }}>
              <strong>Beams</strong>
            </Typography>
            <Box
              style={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={(e) =>
                  ctx.setRf({
                    ...ctx.rf,
                    beams: ctx.handleAddBeamsToRoof(beams),
                  })
                }
              >
                <AddBox style={{ marginRight: 2 }} /> Add Beams
              </Button>
              <Typography variant='subtitle2'>
                {`${beams.length} Beam${beams.length === 1 ? '' : 's'}`}
              </Typography>
            </Box>
            {beams.length > 0 && (
              <Box>
                <Typography
                  color='error'
                  variant='subtitle2'
                  // style={{ marginTop: '5px' }}
                >
                  <em>* direction in relation to louvered blades</em>
                </Typography>
              </Box>
            )}
            {beams?.length > 0 && <DrawBeams rf={ctx.rf} />}
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ paddingTop: '10px' }}>
              <strong>Posts</strong>
            </Typography>
            <Box
              style={{
                display: 'flex',
                gap: '16px',
                alignItems: 'center',
                marginBottom: '5px',
              }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={(e) =>
                  ctx.setRf({
                    ...ctx.rf,
                    posts: ctx.handleAddPostsToRoof(posts),
                  })
                }
              >
                <AddBox style={{ marginRight: 2 }} /> Add Posts
              </Button>
              <Typography variant='subtitle2'>
                {`${posts.length} Post${posts.length === 1 ? '' : 's'}`}
              </Typography>
            </Box>
            {posts?.length > 0 && <DrawPosts rf={ctx.rf} />}
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <strong>Roof Drawing</strong>
            </Typography>
            {!ctx.rf.file ? (
              <input
                id='file'
                name='file'
                type='file'
                // value={ctx?.rf?.fileName?.length > 0 ? ctx.rf.fileName : ''}
                // disabled={ctx.rf.file}
                disabled
                onChange={(e) => addFileHandler(e)}
              />
            ) : (
              ctx.rf.fileName
            )}
            {ctx.rf.file && (
              <Link
                onClick={(e) => clearFileHandler(e)}
                size='small'
                color='error'
                component='button'
                variant='body2'
              >
                <DeleteForever fontSize='small' />
              </Link>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <strong>Additional Details</strong>
            </Typography>
            <FormControl required fullWidth>
              <TextField
                // required
                type='text'
                multiline
                value={ctx.rf.details}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  ctx.setRf({
                    ...ctx.rf,
                    details: e.target.value,
                  })
                }
              />
            </FormControl>
          </Grid>
          <Button
            type='submit'
            className={classes.submit}
            fullWidth
            variant='contained'
            color='primary'
            disabled={
              ctx.isAdmin &&
              order &&
              (status === 'Approved' || status === 'Rejected')
            }
            size='medium'
          >
            {order || ctx.rf.roofId.length > 0
              ? 'Update Order'
              : 'Add to Order'}
          </Button>
        </Grid>
      </form>
    </div>
  );
};

const StoreRoofs = ({ order, status }) => {
  const ctx = useContext(StoreContext);
  return (
    <>
      {ctx.loading ? (
        <Loader />
      ) : ctx.error ? (
        <Message severity='error'>{ctx.error}</Message>
      ) : (
        <RoofForm order={order} status={status} />
      )}
    </>
  );
};

export { StoreRoofs };
