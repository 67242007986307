import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// reducer imports
import {
  userLoginReducer,
  userRegisterReducer,
  userPasswordResetReducer,
  adminUserListReducer,
  adminUpdateUserReducer,
  adminUpdateUsersReducer,
  adminBcCustomerListReducer,
  adminListOrdersReducer,
  adminTicketListReducer,
  adminPortalPricingReducer,
  bcItemsListReducer,
  bcPortalItemsListReducer,
  bcSalesQuoteInitReducer,
  cartReducer,
  orderCreateReducer,
  orderDetailsReducer,
  orderListMyReducer,
  docListReducer,
  docDownloadReducer,
  docRoofDownloadReducer,
  fileListReducer,
  ticketCreateReducer,
  ticketDetailsReducer,
  myTicketListReducer,
  adminPortalPricingSaveReducer,
  adminUpdateBcCustomersReducer,
  adminCustomerTiersReducer,
} from './reducers';

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userPasswordReset: userPasswordResetReducer,
  adminUserList: adminUserListReducer,
  adminUpdate: adminUpdateUserReducer,
  adminUpdateUsers: adminUpdateUsersReducer,
  adminBcCustomerList: adminBcCustomerListReducer,
  adminUpdateBcCustomers: adminUpdateBcCustomersReducer,
  adminCustomerTiers: adminCustomerTiersReducer,
  adminListOrders: adminListOrdersReducer,
  adminTicketList: adminTicketListReducer,
  adminPortalPricing: adminPortalPricingReducer,
  adminPortalPricingSave: adminPortalPricingSaveReducer,
  bcItemsList: bcItemsListReducer,
  bcPortalItemsList: bcPortalItemsListReducer,
  bcSalesQuoteInit: bcSalesQuoteInitReducer,
  cart: cartReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderListMy: orderListMyReducer,
  docList: docListReducer,
  docDownload: docDownloadReducer,
  docRoofDownload: docRoofDownloadReducer,
  fileList: fileListReducer,
  ticketCreate: ticketCreateReducer,
  ticketDetails: ticketDetailsReducer,
  myTicketList: myTicketListReducer,
});

const userInfoFromLocalStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const cartInfoFromLocalStorage = localStorage.getItem('cartInfo')
  ? JSON.parse(localStorage.getItem('cartInfo'))
  : { userDetails: {}, screens: [], roofs: [], parts: [] };

const orderInfoFromLocalStorage = localStorage.getItem('orderInfo')
  ? JSON.parse(localStorage.getItem('orderInfo'))
  : { userDetails: {}, screens: [], roofs: [], parts: [] };

const initialState = {
  userLogin: {
    userInfo: userInfoFromLocalStorage,
  },
  cart: {
    cartInfo: cartInfoFromLocalStorage,
    orderInfo: orderInfoFromLocalStorage,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;