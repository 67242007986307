import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { clearCart } from '../actions';
import { StoreContext } from '../context';
import { DialogOrderUserContinue, FileDialog } from '../components';

import { makeStyles, Box, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  clearBtn: {
    marginRight: 5,
  },
}));

const CartButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const ctx = useContext(StoreContext);

  const { cartInfo } = ctx;
  const { status, _id, userDetails } = cartInfo;
  const { bc_customer_displayName: dealer } = userDetails;

  const handleClearCart = () => {
    dispatch(clearCart());
    history.replace({ search: '' });
    window.location.reload();
  };

  return (
    status !== undefined && (
      <Box className={classes.btns}>
        <Button
          onClick={handleClearCart}
          variant='contained'
          color='secondary'
          size='small'
          className={classes.clearBtn}
        >
          {status === 'In Cart' ? 'Clear Cart' : 'New Order'}
        </Button>
        {_id?.length > 0 && <FileDialog dealerName={dealer} orderId={_id} />}
        <DialogOrderUserContinue eD={ctx.extraDetails} />
      </Box>
    )
  );
};

export { CartButtons };
