import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { createOrder } from '../actions';

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  ButtonGroup,
  // Link,
  InputLabel,
} from '@material-ui/core';
import { LocalShipping } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    marginLeft: 'auto',
    marginRight: 5,
  },
  saveBtn: {
    marginRight: 5,
  },
  dialogBtn: {
    marginLeft: 'auto',
    marginRight: 5,
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

function TrackingDialog({ order, list }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [shippingAgent, setShippingAgent] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTrackingNumber('');
    setOpen(false);
  };

  const handleTracking = (e) => {
    e.preventDefault();
    order = {
      ...order,
      shippingAgent: shippingAgent,
      trackingNumber: trackingNumber,
      status: 'Shipped',
    };
    dispatch(createOrder(order));
    window.location.reload();
  };

  return (
    <>
      {/* <Link
        onClick={handleClickOpen}
        // size='small'
        color='secondary'
        component='button'
        // variant='body2'
      > */}
      <LocalShipping
        color='secondary'
        style={{ width: 20, height: 20, marginLeft: 5, cursor: 'pointer' }}
        onClick={handleClickOpen}
      />
      {/* </Link> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='tracking-dialog-title'
        style={{ minWidth: '450' }}
      >
        <DialogTitle id='tracking-dialog-title'>{`Shipping order: ${order.orderName}`}</DialogTitle>
        <DialogTitle>Please add a tracking number below.</DialogTitle>
        <DialogContent>
          <InputLabel shrink style={{ fontWeight: 'bold' }}>
            Shipping Agent
          </InputLabel>
          <TextField
            autoFocus
            value={shippingAgent}
            margin='dense'
            id='shippingAgent'
            // label='Shipping Agent'
            type='text'
            fullWidth
            onChange={(e) => setShippingAgent(e.target.value)}
          />
          <InputLabel shrink style={{ fontWeight: 'bold' }}>
            Tracking Number
          </InputLabel>
          <TextField
            autoFocus
            value={trackingNumber}
            margin='dense'
            id='trackingNumber'
            // label='Tracking Number'
            type='text'
            fullWidth
            onChange={(e) => setTrackingNumber(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup className={classes.btns}>
            <Button
              onClick={handleClose}
              variant='contained'
              color='secondary'
              size='small'
              className={classes.cancelBtn}
            >
              CANCEL
            </Button>
            <Button
              onClick={(e) => handleTracking(e)}
              variant='contained'
              color='primary'
              size='small'
              disabled={
                trackingNumber.length === 0 || shippingAgent.length === 0
              }
            >
              SHIP
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { TrackingDialog };
