import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { scrKitMapper } from '../utils/kitMapper';

import {
  BC_LIST_ITEMS_REQUEST,
  BC_LIST_ITEMS_SUCCESS,
  BC_LIST_ITEMS_FAIL,
  BC_LIST_PORTAL_ITEMS_REQUEST,
  BC_LIST_PORTAL_ITEMS_SUCCESS,
  BC_LIST_PORTAL_ITEMS_FAIL,
  BC_SALES_QUOTE_REQUEST,
  BC_SALES_QUOTE_SUCCESS,
  BC_SALES_QUOTE_FAIL,
} from '../constants';

// const rmolMarkup = (price) => {
//   const markup = 1.12;
//   if (
//     userInfo?.bc_customer_displayName === 'Rocky Mountain Outdoor Living'
//   ) {
//     return price * markup;
//   } else {
//     return price;
//   }
// };

// const addPriceToItem = (item) => {
//   const currency = userInfo.bc_customer_priceGroup;
//   const price = item.prices.find(
//     (itemPrice) => itemPrice.salesCode === currency
//   )?.unitPrice;
//   const unitMeasure = item.prices.find(
//     (itemPrice) => itemPrice.salesCode === currency
//   )?.unitMeasure;
//   return {
//     ...item,
//     customerPrice: price !== undefined ? rmolMarkup(price) : 0,
//     customerUnitMeasure: unitMeasure !== undefined ? unitMeasure : '',
//   };
// };
// const items = await data.map((item) => addPriceToItem(item));

export const bcItemsList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BC_LIST_ITEMS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };

    
    const { data } = await axios.get('/api/bc/items', config);


    const items = await data?.bcScreenKits?.items.map((item) => {
      const currency = userInfo?.bc_customer_priceGroup;
      const price = item?.prices?.find(
        (itemPrice) => itemPrice?.salesCode === currency
      )?.unitPrice;
      const unitMeasure = item?.prices?.find(
        (itemPrice) => itemPrice?.salesCode === currency
      )?.unitMeasure;
      const customerPrice =
        price !== undefined ? price : 0;
        Object.keys(item.attributes).forEach(key => {
          delete item.attributes[key]._id;
      });
      item.customerPrice = customerPrice
      const newItem = item
      return newItem;
    });

    const boms = await data?.bcScreenKits?.BOMS.map((bom) => {
      bom.childItems.map((item) => {
        const currency = userInfo?.bc_customer_priceGroup;
        const price = item?.prices?.find(
          (itemPrice) => itemPrice?.salesCode === currency
        )?.unitPrice;
      
        let unitMultiplier = 1; 
        const match = item.unitOfMeasureCode.match(/(\d+)\/(\d+)/); 
        
        if (match) {
            const numerator = parseFloat(match[1]);
            const denominator = parseFloat(match[2]);
            unitMultiplier = numerator / denominator; 
        }
        const actualPrice = price * Number(item.quantityPer) * unitMultiplier;
        const customerPrice =
          price !== undefined ? actualPrice : 0;
          Object.keys(item.attributes).forEach(key => {
            delete item.attributes[key]._id;
        });

        item.customerPrice = customerPrice
        const newItem = item
        return newItem;
      })
      const totalPrice = bom.childItems.reduce((total, item) => {
        return total + item.customerPrice;
      }, 0);
      bom.customerPrice = totalPrice
      return bom
    });


    const roofItems = await data?.bcRoofKits?.map((item) => {
      const currency = userInfo?.bc_customer_priceGroup;
      const price = item?.prices?.find(
        (itemPrice) => itemPrice?.salesCode === currency
      )?.unitPrice;
      const unitMeasure = item?.prices?.find(
        (itemPrice) => itemPrice?.salesCode === currency
      )?.unitMeasure;
      const customerPrice =
        price !== undefined ? price : 0;
        Object.keys(item.attributes).forEach(key => {
          delete item.attributes[key]._id;
      });
      const newItem = {
        attributes: item.attributes,
        baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
        displayName: item.displayName,
        displayName2: item.displayName2,
        id: item.id,
        itemCategoryCode: item.itemCategoryCode,
        itemCategoryId: item.itemCategoryId,
        number: item.number,
        type: item.type,
        unitOfMeasure: item.unitOfMeasure,
        customerPrice: customerPrice > 0 ? customerPrice : 0,
        unitOfMeasure: unitMeasure !== undefined ? unitMeasure : '',
      };
      return newItem;
    });







    data.bcScreenKits.items = items
    data.bcScreenKits.BOMS = boms
    data.bcRoofKits = roofItems
    await dispatch({
      type: BC_LIST_ITEMS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: BC_LIST_ITEMS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const bcPortalItemsList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BC_LIST_PORTAL_ITEMS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get(`/api/bc/portalItems`, config);
  

    await dispatch({
      type: BC_LIST_PORTAL_ITEMS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: BC_LIST_PORTAL_ITEMS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const bcSalesQuote = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BC_SALES_QUOTE_REQUEST,
    });

    // console.log('order', order);

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const {
      bcItemsList: { screenKits, roofKits },
    } = getState();
    const { screens, roofs } = order;
    const mappedScrKits =
      !_.isEmpty(screens) &&
      screens.map((screen) => {
        return scrKitMapper(screen, screenKits);
      });
      let mappedRfKits =
      !_.isEmpty(roofs) &&
      roofs.map(async (roof) => {
        const pricingInfo = {
          roof: roof,
          kits: roofKits
        } 
        const mappedRfKitsRes  = await axios.post(`/api/orders/getorderprices`, pricingInfo, config);
        return mappedRfKits = mappedRfKitsRes.data
      });
    const kittedOrder = {
      ...order,
      screens: mappedScrKits ? mappedScrKits : [],
      roofs: mappedRfKits ? mappedRfKits : [],
    };

    // console.log('kittedOrder', kittedOrder);

    const { data } = await toast.promise(
      axios.post('/api/bc/salesQuoteInit', kittedOrder, config),
      {
        pending: 'Sales Quote pending...',
        success: {
          render({ data }) {
            return `Sales Quote: ${data.data.number} created!`;
          },
        },
        error: 'Sales Quote not created.',
      }
    );

    const quotedOrder = {
      orderId: order.orderId,
      quoteId: data.number,
    };

    const { data: updatedOrder } = await axios.post(
      `/api/orders/quotes`,
      quotedOrder,
      config
    );

    // console.log(data, updatedOrder);

    await dispatch({
      type: BC_SALES_QUOTE_SUCCESS,
      payload: [data, updatedOrder],
    });
  } catch (err) {
    dispatch({
      type: BC_SALES_QUOTE_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};