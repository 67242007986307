import React, { useContext } from 'react';

import { StoreContext } from '../context';
import { InfoDialog } from '.';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { AddBox, DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: '99%',
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  splitCol: {
    display: 'flex',
  },
  screenSideIndicator: {
    maxWidth: '65%',
  },
  headerBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'rowReverse',
    gap: '16px',
  },
  titleBox: {
    flexGrow: 1,
  },
}));

const DrawBlades = ({ rf }, type) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);
  const { blades } = rf;

  const deleteBladeHandler = (e, i) => {
    e.preventDefault();
    const newBlades = [...blades];
    newBlades.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      blades: newBlades,
    });
  };

  return (
    <>
      <InfoDialog type={'BladeSizing'} />
      {blades?.length > 0 &&
        blades.map((blade, i) => (
          <Grid container item xs={12} spacing={1} key={i}>
            <Grid item xs={4}>
              <FormControl className={classes.formControl} required>
                <InputLabel shrink>Type</InputLabel>
                <Select
                  name='bladeType'
                  required
                  value={blade.bladeType}
                  onChange={(e) => ctx.handleChangeBlade(e, i)}
                  style={{ paddingTop: 5 }}
                >
                  {/* <MenuItem value={'S-Blade'}>S-Blade</MenuItem> */}
                  <MenuItem value={'B-Blade'}>B-Blade</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl className={classes.formControl} required>
                <TextField
                  name='bladeFeet'
                  style={{ paddingTop: 5 }}
                  required
                  value={blade.bladeFeet}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => ctx.handleChangeBlade(e, i)}
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 1,
                    max: 14
                  }}
                  label='Feet'
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl className={classes.formControl} required>
                <TextField
                  name='bladeInches'
                  style={{ paddingTop: 5 }}
                  required
                  value={blade.bladeInches}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => ctx.handleChangeBlade(e, i)}
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: 0,
                    max: 11.99,
                  }}
                  label='Inches'
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Box display='flex'>
                <FormControl className={classes.formControl} required>
                  <TextField
                    name='bladeQty'
                    style={{ paddingTop: 5 }}
                    required
                    value={blade.bladeQty}
                    onFocus={(e) => e.target.select()}
                    onChange={(e) => ctx.handleChangeBlade(e, i)}
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    label='Qty'
                  />
                </FormControl>
                <Link
                  onClick={(e) => deleteBladeHandler(e, i)}
                  size='small'
                  color='error'
                  component='button'
                  variant='body2'
                >
                  <DeleteForever fontSize='small' />
                </Link>
              </Box>
            </Grid>
          </Grid>
        ))}
    </>
  );
};

const StoreRoofsBlades = ({ rf }, type) => {
  const ctx = useContext(StoreContext);
  const { blades } = rf;

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        alignItems: 'center',
        marginBottom: '5px',
      }}
    >
      <Grid item container xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={'auto'}>
          <Typography>
            <strong>Blades</strong>
          </Typography>
        </Grid>
        <Grid item>
          <InfoDialog type={'Blades'} />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={(e) =>
            ctx.setRf({
              ...ctx.rf,
              blades: ctx.handleAddBladesToRoof(blades),
            })
          }
        >
          <AddBox style={{ paddingRight: 2 }} /> Add
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Typography variant='subtitle2'>
          {`${blades.length} Blade${blades.length === 1 ? '' : 's'}`}
        </Typography>
      </Grid>
      {blades?.length > 0 && <DrawBlades rf={ctx.rf} />}
    </Grid>
  );
};

export { StoreRoofsBlades };
