import React from 'react';

import { numberFormat } from '../utils/numberFormatter';
import { StoreCardButtons } from './';

import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 216,
    maxWidth: 216,
    minHeight: 390,
    maxHeight: 390,
    borderRadius: 6,
    marginBottom: 15,
    display: 'flex',
    flexDirection: 'column',
    justiyContent: 'space-between',
  },
  standoutCard: {
    maxWidth: 789,
    borderRadius: 6,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justiyContent: 'space-between',
  },
  cardMedia: {
    height: 140,
  },
  cardPrice: {
    marginRight: 'auto',
  },
  cardButtons: {
    marginLeft: 'auto',
  },
  CardActions: {
    marginTop: 'auto',
  },
}));

const StoreCard = ({ standout, image, title, description, price, itemId }) => {
  const classes = useStyles();

  return (
    <Card className={standout ? classes.standoutCard : classes.card}>
      <CardMedia className={classes.cardMedia} image={image} />
      <CardContent>
        <Typography gutterBottom variant='h6' color='textPrimary'>
          {title}
        </Typography>
        <Typography variant='body2' color='textSecondary' component='p'>
          {description}
        </Typography>
      </CardContent>
      <CardActions className={classes.CardActions}>
        <Typography className={classes.cardPrice}>
          {!standout && numberFormat('cur-display', price)}
        </Typography>
        {!standout && (
          <StoreCardButtons
            key={itemId}
            itemId={itemId}
            title={title}
            price={price}
            className={classes.cardButtons}
          />
        )}
      </CardActions>
    </Card>
  );
};

export { StoreCard };
