

const mapBom = (bom) => {
  const mappedBom = {
    childItems: bom.childItems.map((item) => {
      return {
        attributes: item.attributes,
        customerPrice: item.customerPrice,
        description: item.description,
        id: item.id,
        name: item.name,
        quantityPer: item.quantityPer, 
        unitOfMeasureCode: item.unitOfMeasureCode
      }
    }), 
    customerPrice: bom.customerPrice, 
    totalPrice: bom.totalPrice ? bom.totalPrice: bom.customerPrice,
    parentId: bom.parentId,
    description: bom.description,
    unitOfMeasure: bom.unitOfMeasure
  }
  return mappedBom
}
const mapItem = (item) => {
  const mappedItem = {
    attributes: item.attributes,
    baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
    customerPrice: item.customerPrice,
    displayName: item.displayName,
    displayName2: item.displayName2,
    id: item.id,
    itemCategoryCode: item.itemCategoryCode,
    itemCategoryId: item.itemCategoryId,
    number: item.number,
    type: item.type,
    baseUnitOfMeasureCode: item.baseUnitOfMeasureCode,
    totalPrice: item.totalPrice,
  }
  return mappedItem
}

const getHardwareKit = (screen, kits) => {
  let color = screen.options.jobColor
    if(screen.options.jobColor === "MF"){
      color = "Mill"
  }
  let hardwareBOM = {}
  if(screen.options["Housing Size"] === "5.5" && screen.options["Housing Shape"] === "Curved"){
    hardwareBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-5HC") && bom.childItems[3].name.includes(color))
  }
  else if(screen.options["Housing Size"] === "5.5" && screen.options["Housing Shape"] === "Square"){
    hardwareBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-5HS") && bom.childItems[3].name.includes(color))
  }
  else if(screen.options["Housing Size"] === "7" && (screen.measurements.width > 264 && screen.measurements.width < 300)  ){
    hardwareBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-7HC-22") && bom.childItems[3].name.includes(color))
  } else{
    hardwareBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-7HC") && bom.childItems[3].name.includes(color))
  }
  return mapBom(hardwareBOM)
}

const getWidthKit = (screen, kits) => {
  let color = screen.options.jobColor
    if(screen.options.jobColor === "MF"){
      color = "Mill"
  }
  let widthBOM = {}
  const height = (Math.max(screen.measurements.dropLeft, screen.measurements.dropRight))
  const width = (screen.measurements.width)
  if((width >= 36 && width <= 264)){
    if(height < 132){
      if(screen.options["Housing Shape"] === "Curved"){
        widthBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-5WC-22") && bom.childItems[3].name.includes(color))
      } else{
        widthBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-5WS-22") && bom.childItems[3].name.includes(color))
      }
    } else{
      widthBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-7WC-22") && bom.childItems[3].name.includes(color))
    }
  } else if(width >= 264 && width <= 300){
    widthBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-7WC-25") && bom.childItems[3].name.includes(color))
  } else{
    widthBOM = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-7WC-30") && bom.childItems[3].name.includes(color))
  }
  widthBOM.totalPrice = widthBOM.customerPrice * (Number(screen.measurements.width)/12)
  return mapBom(widthBOM)
}
const getZipperCKit = (screen, kits) => {
  const height = (Math.max(screen.measurements.dropLeft, screen.measurements.dropRight))
  let zipper = ""
  if(screen.options.jobColor === "White"){
    zipper = kits.items.find((item) => item.number === "MV-Z8-C-WH")
  } else{
    zipper = kits.items.find((item) => item.number === "MV-Z8-C-BL")
  }
  zipper.totalPrice = ((zipper.customerPrice / 657) * 2) * (height/12)
  return mapItem(zipper)
}
const getZipperMKit = (screen, kits) => {
  let zipper = ""
  if(screen.options.jobColor === "White"){
    zipper = kits.items.find((item) => item.number === "MV-Z8-M-WH")
  } else{
    zipper = kits.items.find((item) => item.number === "MV-Z8-M-BL")
  }
  zipper.totalPrice = ((zipper.customerPrice / 327) * 2) * (Number(screen.measurements.width)/12)
  return mapItem(zipper)
}
const getBottomBarKit = (screen, kits) => {
  const bottomBar = kits.items.find(item =>
    item.attributes.some(attr => 
      attr.attributeName === "Kit-Bottom Bar Finish" && attr.attributeValue === screen.options["Bottom Bar Finish"]
    )
  );
  bottomBar.totalPrice =  (bottomBar.customerPrice/250) * (Number(screen.measurements.width)/12)
  return mapItem(bottomBar)
}
const getMatieralKit = (screen, kits) => {
  const material = kits.items.find(item =>
    item.attributes.some(attr => 
      attr.attributeName === "Kit-Material Type" && 
      attr.attributeValue === screen.options.materialType
    ) &&
    item.attributes.some(attr => 
      attr.attributeName === "Kit-" + screen.options.materialType + " Color" && 
      attr.attributeValue ===  screen.options[`${screen.options.materialType} Color`]
    )
  );
  let quantity = 1/114
  if(material.number.includes("SM-S80") || material.number.includes("SM-TS")){
    quantity = 1/100
  } else if(material.number.includes("SM-S90") || material.number.includes("SM-S95") || material.number.includes("VIN-54-90")){
    quantity = 1/90
  } else if(material.number.includes("WEB-62")){
    quantity = 1/150
  }
  material.totalPrice = (material.customerPrice * quantity) * (Number(screen.measurements.width)/12)
  return mapItem(material)
}
const getAngleKit = (screen, kits) => {
  let color = screen.options.jobColor
    if(screen.options.jobColor === "MF"){
      color = "Mill"
  }
  const angle = kits.items.find(item =>
    item.attributes.some(attr => 
      attr.attributeName === "Kit-Angle Size" 
    )&&
    item.attributes.some(attr => 
      attr.attributeName === "Color" && attr.attributeValue === color
    )
  );
  const height = (Math.max(screen.measurements.dropLeft, screen.measurements.dropRight))
  let quantity = 1
  if(height > 132){
    quantity = 2
  }
  angle.totalPrice = angle.customerPrice * quantity
  return mapItem(angle)
}
const getMotorKit = (screen, kits) => {
  const motorBom = kits.BOMS.find(bom =>
    bom.childItems.some(child => 
     child.attributes.some(attr =>  
      attr.attributeName === "Kit-Motor Type" && 
      attr.attributeValue === screen.options["Motor Type"])
    )
    
  );
  return mapBom(motorBom)
}
const getLeftTrackKit = (screen, kits) => {
  let color = screen.options.jobColor
    if(screen.options.jobColor === "MF"){
      color = "Mill"
  }
  let trackLeftKit = {}
  const height = (Math.max(screen.measurements.dropLeft, screen.measurements.dropRight))
  if(screen.options["Track Left"] === "Standard"){
    if(height < 132){
      trackLeftKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-ST") &&  !bom.parentId.includes("EXT") && bom.childItems[0].name.includes(color))
    } else{
      trackLeftKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-ST") &&  bom.parentId.includes("EXT") && bom.childItems[0].name.includes(color))
    }
  } else if(screen.options["Track Left"] === "Recessed"){
    if(height < 132){
      trackLeftKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-RT") &&  !bom.parentId.includes("EXT") && bom.childItems[0].name.includes(color))
    } else{
      trackLeftKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-RT") &&  bom.parentId.includes("EXT") && bom.childItems[0].name.includes(color))
    }
  }else{
    if(screen.options.jobColor ===  'White'){
      trackLeftKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-CT-WH"))
    } else{
      trackLeftKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-CT-BL"))
    }
  }
  return mapBom(trackLeftKit)
}
const getRightTrackKit = (screen, kits) => {
  let color = screen.options.jobColor
    if(screen.options.jobColor === "MF"){
      color = "Mill"
  }
  let trackRightKit = {}
  const height = (Math.max(screen.measurements.dropLeft, screen.measurements.dropRight))
  if(screen.options["Track Right"] === "Standard"){
    if(height < 132){
      trackRightKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-ST") &&  !bom.parentId.includes("EXT") && bom.childItems[0].name.includes(color))
    } else{
      trackRightKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-ST") &&  bom.parentId.includes("EXT") && bom.childItems[0].name.includes(color))
    }
  } else if(screen.options["Track Right"] === "Recessed"){
    if(height < 132){
      trackRightKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-RT") &&  !bom.parentId.includes("EXT") && bom.childItems[0].name.includes(color))
    } else{
      trackRightKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-RT") &&  bom.parentId.includes("EXT") && bom.childItems[0].name.includes(color))
    }
  }else{
    if(screen.options.jobColor ===  'White'){
      trackRightKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-CT-WH"))
    } else{
      trackRightKit = kits.BOMS.find((bom) => bom.parentId.startsWith("BOM-RS-CT-BL"))
    }
  }
  return mapBom(trackRightKit)
}



export { 
  getHardwareKit, 
  getWidthKit, 
  getZipperCKit, 
  getZipperMKit, 
  getBottomBarKit, 
  getMatieralKit, 
  getAngleKit, 
  getMotorKit, 
  getLeftTrackKit, 
  getRightTrackKit 
};