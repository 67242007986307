import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Typography,
  Link,
  makeStyles,
  Container,
  Grid,
  Button,
  Box,
} from '@material-ui/core';
import { Email, Phone } from '@material-ui/icons';

import '../assets/css/footer.css';
import Logo from '../assets/images/Suncoast-Built-logo-white.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: 15,
    padding: 10,
    backgroundColor: '#000000',
    color: '#ffffff',
  },
  navBtn: {},
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div>
        <Container>
          <Grid container item xs={12}>
            <Grid item md={6} xs={12}>
              <img
                src={Logo}
                height='35'
                width='365'
                className={classes.logo}
                alt='logo'
              />
              <Typography gutterBottom variant='body2'>
                Copyright &copy;{'  '}
                <Link
                  color='inherit'
                  href='https://suncoastbuilt.com'
                  target='_blank'
                >
                  Suncoast Built{' '}
                </Link>{' '}
                {'(' + new Date().getFullYear() + ')'}
              </Typography>
            </Grid>
            <Grid
              container
              direction='column'
              item
              md={4}
              xs={12}
              // alignContent='center'
              justifyContent='center'
            >
              <Typography variant='subtitle2'>Dealer Support</Typography>
              <Box display='flex' flexDirection='row'>
                <Email color='secondary' fontSize='small' />
                <Typography
                  style={{ marginLeft: 2 }}
                  variant='body2'
                  className='e-mail'
                  data-user='info'
                  data-website='suncoastbuilt.com'
                ></Typography>
              </Box>
              <Box display='flex' flexDirection='row'>
                <Phone color='secondary' fontSize='small' />
                <Typography
                  style={{ marginLeft: 2 }}
                  variant='body2'
                  className='phone'
                  data-areacode='403'
                  data-prefix='668'
                  data-suffix='4440'
                ></Typography>
              </Box>
            </Grid>
            <Grid
              container
              item
              md={2}
              xs={12}
              alignContent='center'
              justifyContent='flex-end'
            >
              <Button
                variant='contained'
                color='secondary'
                component={RouterLink}
                to={{
                  pathname: '/account',
                  search: 'tab=tickets',
                }}
              >
                Feedback
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
    </footer>
  );
};

export { Footer };
