import React from 'react';

import { fracToDec } from '../utils/storeCalcs';

import { Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const DropDirection = ({ l, lf, r, rf }) => {
  const left = Number(l) + fracToDec(lf);
  const right = Number(r) + fracToDec(rf);

  if (Number(left) > Number(right)) {
    return (
      <Typography
        color='error'
        variant='subtitle2'
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: 'auto',
        }}
      >
        Screen slopes down right to left. (
        <KeyboardBackspaceIcon />)
      </Typography>
    );
  } else if (Number(left) < Number(right)) {
    return (
      <Typography
        color='error'
        variant='subtitle2'
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: 'auto',
        }}
      >
        Screen slopes down left to right. (
        <ArrowRightAltIcon />)
      </Typography>
    );
  }
  return null;
};

export { DropDirection };