import React, { useContext } from 'react';

import { StoreContext } from '../context';
import { InfoDialog } from '.';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  // TextField,
  Typography,
} from '@material-ui/core';
import { AddBox, DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: '99%',
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  splitCol: {
    display: 'flex',
  },
  screenSideIndicator: {
    maxWidth: '65%',
  },
  headerBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'rowReverse',
    gap: '16px',
  },
  titleBox: {
    flexGrow: 1,
  },
}));

const DrawPosts = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const classes = useStyles();
  const { posts } = rf;

  const deletePostHandler = (e, i) => {
    e.preventDefault();
    const newPosts = [...posts];
    newPosts.splice(i, 1);
    ctx.setRf({
      ...ctx.rf,
      posts: newPosts,
    });
  };
  const getPostLengthOptions = (postStyle) => {
    if (postStyle?.startsWith('8x8')) {
      return ['10', '12', '25'];
    }
    return ['10', '12', '19\'4"'];
  };
  
  return (
    posts?.length > 0 &&
    posts.map((post, i) => (
<Grid container item xs={12} spacing={1} key={i}>
  <Grid item xs={7}>
    <FormControl className={classes.formControl} required>
      <InputLabel shrink>Post Style</InputLabel>
      <Select
        name="postStyle"
        required
        value={post.postStyle}
        onChange={(e) => ctx.handleChangePost(e, i)}
      >
        <MenuItem value={'6x6 Corner Saddle'}>6x6 Corner Saddle</MenuItem>
        <MenuItem value={'6x6 Flat Saddle'}>6x6 Flat Saddle</MenuItem>
        <MenuItem value={'6x6 Flush'}>6x6 Flush</MenuItem>
        <MenuItem value={'8x8 Corner Saddle'}>8x8 Corner Saddle</MenuItem>
        <MenuItem value={'8x8 Flat Saddle'}>8x8 Flat Saddle</MenuItem>
        <MenuItem value={'8x8 Flush'}>8x8 Flush</MenuItem>
      </Select>
    </FormControl>
  </Grid>
  <Grid item xs={5}>
    <Box key={i} display="flex">
      <FormControl className={classes.formControl} required fullWidth>
        <InputLabel shrink>Post Length</InputLabel>
        <Select
          name="postLength"
          required
          value={post.postLength}
          onChange={(e) => ctx.handleChangePost(e, i)}
        >
          {getPostLengthOptions(post.postStyle).map((lengthOption) => (
            <MenuItem key={lengthOption} value={lengthOption}>
              {lengthOption}'
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Link
        onClick={(e) => deletePostHandler(e, i)}
        size="small"
        color="error"
        component="button"
        variant="body2"
      >
        <DeleteForever fontSize="small" />
      </Link>
    </Box>
  </Grid>
</Grid>

    ))
  );
};

const StoreRoofsPosts = ({ rf }) => {
  const ctx = useContext(StoreContext);
  const { posts } = rf;

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        alignItems: 'center',
        marginBottom: '5px',
      }}
    >
      <Grid item container xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={'auto'}>
          <Typography>
            <strong>Posts</strong>
          </Typography>
        </Grid>
        <Grid item>
          <InfoDialog type={'Posts'} />
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={(e) =>
            ctx.setRf({
              ...ctx.rf,
              posts: ctx.handleAddPostsToRoof(posts),
            })
          }
        >
          <AddBox style={{ paddingRight: 2 }} /> Add
        </Button>
      </Grid>
      <Grid item xs={8}>
        <Typography variant='subtitle2'>
          {`${posts.length} Post${posts.length === 1 ? '' : 's'}`}
        </Typography>
      </Grid>
      {posts?.length > 0 && <DrawPosts rf={ctx.rf} />}
    </Grid>
  );
};

export { StoreRoofsPosts };
