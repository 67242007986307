// all BC items
export const BC_LIST_ITEMS_REQUEST = 'BC_LIST_ITEMS_REQUEST';
export const BC_LIST_ITEMS_SUCCESS = 'BC_LIST_ITEMS_SUCCESS';
export const BC_LIST_ITEMS_FAIL = 'BC_LIST_ITEMS_FAIL';

// all BC items
export const BC_LIST_PORTAL_ITEMS_REQUEST = 'BC_LIST_PORTAL_ITEMS_REQUEST';
export const BC_LIST_PORTAL_ITEMS_SUCCESS = 'BC_LIST_PORTAL_ITEMS_SUCCESS';
export const BC_LIST_PORTAL_ITEMS_FAIL = 'BC_LIST_PORTAL_ITEMS_FAIL';

// BC sales quote
export const BC_SALES_QUOTE_REQUEST = 'BC_SALES_QUOTE_REQUEST';
export const BC_SALES_QUOTE_SUCCESS = 'BC_SALES_QUOTE_SUCCESS';
export const BC_SALES_QUOTE_FAIL = 'BC_SALES_QUOTE_FAIL';
export const BC_SALES_QUOTE_RESET = 'BC_SALES_QUOTE_RESET';