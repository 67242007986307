import { useContext } from 'react';
import { format } from 'date-fns';

import { TicketContext } from '../context';

import { Loader, Message, TicketDialog } from './';

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';

const TicketListMy = () => {
  const ttx = useContext(TicketContext);
  const myTicketsLoading = ttx.myTicketsLoading;
  const myTickets = ttx.myTickets?.sort((a, b) =>
    a.updatedAt > b.updatedAt ? -1 : 1
  );
  const myTicketsNew = myTickets?.filter(
    (ticket) => ticket.ticketStatus.toLowerCase() === 'new'
  );
  const myTicketsProgress = myTickets?.filter(
    (ticket) => ticket.ticketStatus.toLowerCase() === 'in progress'
  );
  const myTicketsClosed = myTickets?.filter(
    (ticket) => ticket.ticketStatus.toLowerCase() === 'closed'
  );
  const myTicketsError = ttx.myTicketsError;

  // // console.log(myTickets);

  return myTicketsLoading ? (
    <Loader />
  ) : myTicketsError ? (
    <Message severity='error'>{myTicketsError}</Message>
  ) : (
    <TableContainer component={Paper}>
      <Table aria-label='myTickets table'>
        <TableHead>
          <TableRow>
            <TableCell colSpan={5}>
              <TicketDialog edit={false} />
            </TableCell>
            <TableCell align='right' colSpan={1}>
              <Typography variant='subtitle2'>
                {`New: ${myTicketsNew?.length} | 
                In Progress: ${myTicketsProgress?.length} | 
                Closed: ${myTicketsClosed?.length}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>Date Submitted</TableCell>
            <TableCell align='left'>Ticket Type</TableCell>
            <TableCell>Ticket Name</TableCell>
            <TableCell>Ticket Status</TableCell>
            <TableCell align='left'>Date Updated</TableCell>
            <TableCell align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {myTickets && myTickets.length > 0 ? (
            myTickets.map((ticket) => (
              <TableRow key={ticket._id}>
                <TableCell align='left'>
                  {format(new Date(ticket.createdAt), 'MMM dd, yyyy')}
                </TableCell>
                <TableCell>{ticket.ticketType}</TableCell>
                <TableCell>{ticket.ticketName}</TableCell>
                <TableCell>{ticket.ticketStatus}</TableCell>
                <TableCell align='left'>
                  {format(new Date(ticket.updatedAt), 'MMM dd, yyyy')}
                </TableCell>
                <TableCell align='right'>
                  <TicketDialog mode='view' ticket={ticket} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align='center'>
                <h3>No orders to display.</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TicketListMy };
