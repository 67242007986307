import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';

import { listMyOrders } from '../actions';
import { numberFormat } from '../utils/numberFormatter';

import { Message, Loader, DeleteDialog } from '../components';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Typography,
  Box,
  makeStyles,
} from '@material-ui/core';
import {
  Pageview,
  Edit,
  ArrowUpward,
  ArrowDownward,
  // GetApp
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  header: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  sortedColumn: {
    backgroundColor: '#f5f5f5',
  },
  column: {},
  sortIcon: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '0.25rem',
  },
}));

const OrderListAccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;
  const orderListMy = useSelector((state) => state.orderListMy);
  const { orders: myOrders, loading } = orderListMy;

  const [sortedField, setSortedField] = useState('updatedAt');
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    }
    dispatch(listMyOrders());
  }, [history, userInfo, dispatch]);

  const sortOrders = (field) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedField(field);
      setSortDirection('asc');
    }
  };
  const orders = myOrders?.orders?.sort((a, b) => {
    // if (sortedField === null) return a.updatedAt > b.updatedAt ? -1 : 1;
    let result;
    if (a[sortedField] > b[sortedField]) result = 1;
    if (a[sortedField] < b[sortedField]) result = -1;
    if (sortDirection === 'desc') result *= -1;
    return result;
  });

  return loading ? (
    <Loader />
  ) : error ? (
    <Message severity='error'>{error}</Message>
  ) : (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell
              align='left'
              className={
                sortedField === 'createdAt'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('createdAt')}
            >
              Date Placed
              {sortedField === 'createdAt' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </TableCell>
            <TableCell
              className={
                sortedField === 'orderName'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('orderName')}
            >
              Order Name
              {sortedField === 'orderName' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </TableCell>
            <TableCell
              className={
                sortedField === 'orderNumber'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('orderNumber')}
            >
              Sales Order Number
              {sortedField === 'orderNumber' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </TableCell>
            <TableCell
              align='right'
              className={
                sortedField === 'totalPrice'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('totalPrice')}
            >
              Total Price
              {sortedField === 'totalPrice' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </TableCell>
            <TableCell
              align='left'
              className={
                sortedField === 'updatedAt'
                  ? classes.sortedHeader
                  : classes.header
              }
              onClick={() => sortOrders('updatedAt')}
            >
              Date Updated
              {sortedField === 'updatedAt' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </TableCell>
            <TableCell
              className={
                sortedField === 'status' ? classes.sortedHeader : classes.header
              }
              onClick={() => sortOrders('status')}
            >
              Status
              {sortedField === 'status' &&
                (sortDirection === 'asc' ? (
                  <ArrowUpward color='action' className={classes.sortIcon} />
                ) : (
                  <ArrowDownward color='action' className={classes.sortIcon} />
                ))}
            </TableCell>
            <TableCell colSpan={2}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders && orders.length > 0 ? (
            orders.map((order) => (
              <TableRow key={order._id}>
                <TableCell
                  align='left'
                  className={
                    sortedField === 'createdAt'
                      ? classes.sortedColumn
                      : classes.column
                  }
                >
                  {format(new Date(order.createdAt), 'MMM dd, yyyy')}
                </TableCell>
                <TableCell
                  className={
                    sortedField === 'orderName'
                      ? classes.sortedColumn
                      : classes.column
                  }
                >
                  {order.orderName}
                </TableCell>
                <TableCell
                  className={
                    sortedField === 'orderNumber'
                      ? classes.sortedColumn
                      : classes.column
                  }
                >
                  {order.orderNumber}
                </TableCell>
                <TableCell
                  align='right'
                  className={
                    sortedField === 'totalPrice'
                      ? classes.sortedColumn
                      : classes.column
                  }
                >
                  {numberFormat('cur-display', order.totalPrice)}
                </TableCell>
                <TableCell
                  align='left'
                  className={
                    sortedField === 'updatedAt'
                      ? classes.sortedColumn
                      : classes.column
                  }
                >
                  {format(new Date(order.updatedAt), 'MMM dd, yyyy')}
                </TableCell>
                <TableCell
                  className={
                    sortedField === 'status'
                      ? classes.sortedColumn
                      : classes.column
                  }
                >
                  {order.status}
                </TableCell>
                <TableCell>
                  {order.status === 'Draft' ? (
                    <Link
                      size='small'
                      color='textSecondary'
                      component={RouterLink}
                      variant='body2'
                      to={{
                        pathname: '/store',
                        search: `order=${order._id}`,
                      }}
                    >
                      <Edit fontSize='small' />
                    </Link>
                  ) : (
                    <Link
                      size='small'
                      color='textSecondary'
                      component={RouterLink}
                      variant='body2'
                      to={{
                        pathname: '/store',
                        search: `order=${order._id}`,
                      }}
                    >
                      <Pageview fontSize='small' />
                    </Link>
                  )}
                </TableCell>
                {order.status === 'Draft' || order.status === 'Rejected' ? (
                  <TableCell>
                    <DeleteDialog order={order} list={true} />
                  </TableCell>
                ) : (
                  <TableCell></TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align='center'>
                <h3>No orders to display.</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box display='flex'>
        <Typography
          variant='caption'
          style={{
            marginLeft: 'auto',
            marginRight: 5,
            marginTop: 5,
            marginBottom: 5,
          }}
          color='error'
          align='right'
        >
          *Only Draft or Rejected orders can be deleted.
        </Typography>
      </Box>
    </TableContainer>
  );
};

export { OrderListAccount };
