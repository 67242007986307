import { TicketContextProvider } from '../context';

import { Box, Typography } from '@material-ui/core';

import { TicketListAdmin, TicketListMy } from '../components';

const TicketList = ({ admin }) => {
  return (
    <TicketContextProvider>
      <Box display='flex' style={{ alignItems: 'center' }}>
        <Typography variant='h6'>
          {admin ? 'Dealer-Submitted' : 'My'} Tickets
        </Typography>
      </Box>
      {admin ? <TicketListAdmin /> : <TicketListMy />}
    </TicketContextProvider>
  );
};

export { TicketList };
