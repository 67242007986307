// ticket list my constants
export const TICKET_LIST_MY_REQUEST = 'TICKET_LIST_MY_REQUEST';
export const TICKET_LIST_MY_SUCCESS = 'TICKET_LIST_MY_SUCCESS';
export const TICKET_LIST_MY_FAIL = 'TICKET_LIST_MY_FAIL';

// ticket update constants
export const TICKET_DETAILS_REQUEST = 'TICKET_DETAILS_REQUEST';
export const TICKET_DETAILS_SUCCESS = 'TICKET_DETAILS_SUCCESS';
export const TICKET_DETAILS_FAIL = 'TICKET_DETAILS_FAIL';

// ticket update constants
export const TICKET_UPDATE_REQUEST = 'TICKET_UPDATE_REQUEST';
export const TICKET_UPDATE_SUCCESS = 'TICKET_UPDATE_SUCCESS';
export const TICKET_UPDATE_FAIL = 'TICKET_UPDATE_FAIL';

// ticket create constants
export const TICKET_CREATE_REQUEST = 'TICKET_CREATE_REQUEST';
export const TICKET_CREATE_SUCCESS = 'TICKET_CREATE_SUCCESS';
export const TICKET_CREATE_FAIL = 'TICKET_CREATE_FAIL';
export const TICKET_CREATE_RESET = 'TICKET_CREATE_RESET';

// ticket delete constants
export const TICKET_DELETE_REQUEST = 'TICKET_DELETE_REQUEST';
export const TICKET_DELETE_SUCCESS = 'TICKET_DELETE_SUCCESS';
export const TICKET_DELETE_FAIL = 'TICKET_DELETE_FAIL';

// ticket reset constants
export const TICKET_RESET_REQUEST = 'TICKET_RESET_REQUEST';
export const TICKET_RESET_SUCCESS = 'TICKET_RESET_SUCCESS';
export const TICKET_RESET_FAIL = 'TICKET_RESET_FAIL';
export const TICKET_RESET_LOGOUT = 'TICKET_RESET_LOGOUT';
