import React from 'react';
// import { useLocation } from 'react-router-dom';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 'auto',
  },
}));

const BodyContainer = ({ children }) => {
  const classes = useStyles();

  // const location = useLocation();

  // Set the maxWidth based on the current route
  // let maxWidth = 'lg';
  // if (location.pathname.startsWith('/admin')) {
  //   maxWidth = 'lg';
  // }

  return (
    <Container
      // maxWidth={maxWidth}
      className={classes.container}
    >
      {children}
    </Container>
  );
};

export { BodyContainer };
