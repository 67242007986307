import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { getUser } from './actions';
import { ToastContainer, toast, Slide } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import './assets/css/toasts.css';

import { RouterContextProvider } from './context';
import { SiteContainer, Header, BodyContainer, Footer } from './components';
import {
  HomeView,
  RegisterView,
  LoginView,
  StoreView,
  AdminView,
  AccountView,
  ResetView,
  LibraryView,
} from './views';

import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { basicTheme } from './themes';

const App = () => {
  toast.error();
  toast.success();
  toast.info();
  toast.warn();
  const userLogin = useSelector((state) => state.userLogin);
  const dispatch = useDispatch()
  const { userInfo } = userLogin;
  useEffect(() => {
    if (userInfo) {
      dispatch(getUser(userInfo));
    }
  }, []);
  return (
    <ThemeProvider theme={basicTheme}>
      <RouterContextProvider>
        <CssBaseline />
        <SiteContainer>
          <Header />
          <BodyContainer>
            <ToastContainer
              position='top-right'
              theme={'colored'}
              transition={Slide}
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Route path='/register' component={RegisterView} exact />
            <Route path='/login' component={LoginView} exact />
            <Route path='/reset/:token?' component={ResetView} exact />
            <Route path='/' component={HomeView} exact />
            <Route path='/store/:id?' component={StoreView} exact />
            <Route path='/admin' component={AdminView} exact />
            <Route path='/account' component={AccountView} exact />
            <Route path='/docs' component={LibraryView} exact />
          </BodyContainer>
          <Footer />
        </SiteContainer>
      </RouterContextProvider>
    </ThemeProvider>
  );
};

export default App;
