import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../actions';

import {
  IconButton,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  Fade,
  Tooltip,
  Button,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Person } from '@material-ui/icons';

const StyledMenuList = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    // color: theme.palette.common.white,
    color: theme.palette.primary.contrastText,
  },
}))(MenuList);

const UserMenu = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  const handleToggle = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };
  const handleSignOut = () => {
    dispatch(logout());
  };

  const fullName = `${userInfo.fname} ${userInfo.lname}`;

  return (
    <>
      <Tooltip title={fullName && fullName} placement='top' arrow>
        <IconButton
          data-testid='userMenuBtn'
          onClick={handleToggle('bottom-end')}
        >
          <Person color='secondary' />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ marginTop: 0 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <StyledMenuList
                  autoFocusItem={open}
                  id='list-grow'
                  onKeyDown={handleListKeyDown}
                  variant='menu'
                >
                  <MenuItem>{fullName && fullName}</MenuItem>
                  <hr />
                  {/* <MenuItem
                    component={Button}
                    variant='contained'
                    color='secondary'
                    size='small'
                    onClick={handleSignOut}
                    style={{ margin: 'auto' }}
                  >
                    Sign Out
                  </MenuItem> */}
                  <MenuItem>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={handleSignOut}
                      style={{ margin: 'auto' }}
                      data-testid='signOutBtn'
                      size='small'
                    >
                      Sign Out
                    </Button>
                  </MenuItem>
                </StyledMenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export { UserMenu };
