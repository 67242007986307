import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ObjectID from 'bson-objectid';

import {
  createTicket,
  listMyTickets,
  resetTicketState,
  adminListAllTickets,
  // getTicketDetails,
  // updateTicket,
  // deleteTicket,
} from '../actions';

const ticketState = {
  ticketName: '',
  ticketType: '',
  ticketMessage: '',
  ticketStatus: '',
};

const TicketContext = createContext({
  // edit: false,
  myTicketsLoading: false,
  myTickets: [],
  myTicketsError: null,
  adminTicketsLoading: false,
  adminTickets: [],
  adminTicketsError: null,
  ticket: ticketState,
  ticketState: ticketState,
  createdTicketLoading: false,
  createdTicketError: null,
  createdTicketStatus: null,
  // detailedTicketLoading: false,
  // detailedTicketError: null,
  // detailedTicketTicket: null,
  // setEdit: () => false,
  setTicket: () => ticketState,
  handleCreateTicket: () => {},
  // handleGetTicketDetails: () => {},
});

const TicketContextProvider = (props) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createdTicket = useSelector((state) => state.ticketCreate);
  const {
    loading: createdTicketLoading,
    error: createdTicketError,
    status: createdTicketStatus,
  } = createdTicket;

  // const ticketDetails = useSelector((state) => state.ticketDetails);
  // const {
  //   loading: detailedTicketLoading,
  //   error: detailedTicketError,
  //   ticket: detailedTicket,
  // } = ticketDetails;

  // // console.log(detailedTicket);

  const myTicketList = useSelector((state) => state.myTicketList);
  const {
    loading: myTicketsLoading,
    tickets: myTickets,
    error: myTicketsError,
  } = myTicketList;

  const adminTicketList = useSelector((state) => state.adminTicketList);
  const {
    loading: adminTicketsLoading,
    tickets: adminTickets,
    error: adminTicketsError,
  } = adminTicketList;

  const [ticket, setTicket] = useState(ticketState);
  // const [edit, setEdit] = useState(false);

  // detailedTicket && setTicket(detailedTicket);

  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  useEffect(() => {
    dispatch(listMyTickets());
    if (userInfo?.isAdmin) {
      dispatch(adminListAllTickets());
    }
    if (createdTicketStatus && createdTicketStatus === 201) {
      dispatch(resetTicketState());
      setTicket(ticketState);
      forceUpdate();
    }
  }, [dispatch, userInfo?.isAdmin, forceUpdate, createdTicketStatus]);

  const handleCreateTicket = (t) => {
    // // console.log('from context', t);
    const newTicket = {
      ticketId: ObjectID().toString(),
      user: userInfo._id,
      userDetails: {
        userName: `${userInfo.fname} ${userInfo.lname}`,
        userEmail: userInfo.email,
        bc_customer_id: userInfo.bc_customer_id,
        bc_customer_displayName: userInfo.bc_customer_displayName,
        bc_customer_priceGroup: userInfo.bc_customer_priceGroup,
      },
      ticketName: t.ticketName,
      ticketType: t.ticketType,
      ticketMessage: t.ticketMessage,
      ticketStatus: 'New',
      isOpen: true,
    };
    dispatch(createTicket(newTicket));
  };

  // const handleGetTicketDetails = async (e, tId) => {
  //   e.preventDefault();
  //   if (edit) {
  //     dispatch(getTicketDetails(tId));
  //   }
  // };

  return (
    <TicketContext.Provider
      value={{
        // edit: edit,
        myTicketsLoading: myTicketsLoading,
        myTickets: myTickets,
        myTicketsError: myTicketsError,
        adminTicketsLoading: adminTicketsLoading,
        adminTickets: adminTickets,
        adminTicketsError: adminTicketsError,
        ticket: ticket,
        ticketState: ticketState,
        createdTicketLoading: createdTicketLoading,
        createdTicketError: createdTicketError,
        createdTicketStatus: createdTicketStatus,
        // detailedTicketLoading: detailedTicketLoading,
        // detailedTicketError: detailedTicketError,
        // detailedTicketTicket: detailedTicket,
        // setEdit: setEdit,
        setTicket: setTicket,
        handleCreateTicket: handleCreateTicket,
        // handleGetTicketDetails: handleGetTicketDetails,
      }}
    >
      {props.children}
    </TicketContext.Provider>
  );
};

export { TicketContext, TicketContextProvider };
