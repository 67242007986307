import React, { useContext } from 'react';
import { loremIpsum } from 'react-lorem-ipsum';

import { StoreContext } from '../context';
import { Loader, Message, StoreCard } from '.';

const StoreParts = () => {
  const ctx = useContext(StoreContext);

  const items = ctx.items.sort((a, b) =>
    a.displayName > b.displayName ? 1 : -1
  );

  return (
    <>
      {ctx.loading ? (
        <Loader />
      ) : ctx.error ? (
        <Message severity='error'>{ctx.error}</Message>
      ) : (
        items?.length > 0 &&
        items.map((item) => (
          <StoreCard
            key={item?._id}
            image={`https://picsum.photos/200/300?random=${item?.id}`}
            title={item?.displayName}
            description={loremIpsum({
              p: 1,
              avgWordsPerSentence: 9,
              avgSentencesPerParagraph: 2,
            })}
            price={
              item?.customerPrice && item.customerPrice > 0
                ? item.customerPrice
                : 0.0
            }
            itemId={item?.id}
          />
        ))
      )}
    </>
  );
};

export { StoreParts };
