import {
  DOC_LIST_REQUEST,
  DOC_LIST_SUCCESS,
  DOC_LIST_FAIL,
  DOC_DOWNLOAD_REQUEST,
  DOC_DOWNLOAD_SUCCESS,
  DOC_DOWNLOAD_FAIL,
  DOC_ROOF_DOWNLOAD_REQUEST,
  DOC_ROOF_DOWNLOAD_SUCCESS,
  DOC_ROOF_DOWNLOAD_FAIL,
  DOC_UPLOAD_REQUEST,
  DOC_UPLOAD_SUCCESS,
  DOC_UPLOAD_FAIL,
} from '../constants';

export const docListReducer = (state = {}, action) => {
  switch (action.type) {
    case DOC_LIST_REQUEST:
      return { loading: true };
    case DOC_LIST_SUCCESS:
      return { loading: false, docs: action.payload };
    case DOC_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const docDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case DOC_DOWNLOAD_REQUEST:
      return { loading: true };
    case DOC_DOWNLOAD_SUCCESS:
      return { loading: false, signedUrl: action.payload };
    case DOC_DOWNLOAD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const docRoofDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case DOC_ROOF_DOWNLOAD_REQUEST:
      return { loading: true };
    case DOC_ROOF_DOWNLOAD_SUCCESS:
      return { loading: false, signedUrl: action.payload };
    case DOC_ROOF_DOWNLOAD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const docUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case DOC_UPLOAD_REQUEST:
      return { loading: true };
    case DOC_UPLOAD_SUCCESS:
      return { loading: false, signedUrl: action.payload };
    case DOC_UPLOAD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
