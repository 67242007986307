import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { createOrder, clearCart } from '../actions';
import { StoreContext } from '../context';

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  rejectBtn: {
    marginRight: 'auto',
  },
}));

function RejectDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const ctx = useContext(StoreContext);

  const [open, setOpen] = useState(false);
  const [confirmYes, setConfirmYes] = useState('');
  const { cartInfo } = ctx;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setConfirmYes('');
    setOpen(false);
  };

  const handleReject = (e) => {
    e.preventDefault();
    if (confirmYes.toLowerCase() === 'reject') {
      dispatch(createOrder(cartInfo, null, null, 'Rejected'));
      dispatch(clearCart());
      history.push('/admin');
      window.location.reload();
    }
  };

  return (
    <div>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        onClick={handleClickOpen}
        className={classes.rejectBtn}
      >
        Reject Order
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='reject-dialog-title'
        style={{ minWidth: '450' }}
      >
        <DialogTitle id='reject-dialog-title'>{`Reject order: ${cartInfo?.orderName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`To reject this order, please type REJECT into the field below and click REJECT.`}
          </DialogContentText>
          <TextField
            autoFocus
            value={confirmYes}
            margin='dense'
            id='name'
            label='Reject Order?'
            type='text'
            fullWidth
            onChange={(e) => setConfirmYes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='contained'
            color='secondary'
            size='small'
            className={classes.cancelBtn}
          >
            CANCEL
          </Button>
          <Button
            onClick={(e) => handleReject(e)}
            variant='contained'
            color='primary'
            size='small'
          >
            REJECT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { RejectDialog };
