import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteOrder } from '../actions';

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  dialogBtn: {
    marginLeft: 'auto',
    marginRight: 5,
  },
}));

function DeleteDialog({ order, list }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [confirmYes, setConfirmYes] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setConfirmYes('');
    setOpen(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    if (confirmYes.toLowerCase() === 'delete') {
      dispatch(deleteOrder(order._id));
      setOpen(false);
      window.location.reload();
    }
  };

  return (
    <div>
      {list ? (
        <Link
          onClick={handleClickOpen}
          size='small'
          color='error'
          component='button'
          variant='body2'
        >
          <DeleteForever fontSize='small' />
        </Link>
      ) : (
        <Button
          variant='contained'
          color='primary'
          size='small'
          onClick={handleClickOpen}
          className={classes.dialogBtn}
        >
          Delete Order
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='delete-dialog-title'
        style={{ minWidth: '450' }}
      >
        <DialogTitle id='delete-dialog-title'>{`Delete order: ${order.orderName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`To delete this order, please type DELETE into the field below, then click DELETE.`}
          </DialogContentText>
          <TextField
            autoFocus
            value={confirmYes}
            margin='dense'
            id='name'
            label='Delete order?'
            type='text'
            fullWidth
            onChange={(e) => setConfirmYes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='contained'
            color='secondary'
            size='small'
            className={classes.cancelBtn}
          >
            CANCEL
          </Button>
          <Button
            onClick={(e) => handleDelete(e)}
            variant='contained'
            color='primary'
            size='small'
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { DeleteDialog };
