import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Toolbar, makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  navLnk: {
    padding: 5,
    margin: 5,
    '&:hover': {
      color: '#C69023',
    },
    '&:focus': {
      color: '#C69023',
    },
  },
  navBtn: {
    padding: 5,
    margin: 5,
  },
}));

const Navbar = ({ userInfo }) => {
  const classes = useStyles();

  return (
    <Toolbar>
      {userInfo && userInfo.storeAccess && (
        <Button
          className={classes.navLnk}
          variant='contained'
          color='primary'
          component={RouterLink}
          to='/store'
          size='small'
        >
          STORE
        </Button>
      )}
      <Button
        className={classes.navLnk}
        variant='contained'
        color='primary'
        component={RouterLink}
        to='/docs'
        size='small'
      >
        Library
      </Button>
      <Button
        className={classes.navLnk}
        variant='contained'
        color='primary'
        component={RouterLink}
        to='/account'
        size='small'
      >
        Account
      </Button>
      {userInfo && userInfo.isAdmin && (
        <Button
          className={classes.navBtn}
          variant='contained'
          color='secondary'
          component={RouterLink}
          to='/admin'
          size='small'
        >
          Admin
        </Button>
      )}
    </Toolbar>
  );
};

export { Navbar };
