import React from 'react';

import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles, Collapse, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // '& > * + *': {
    //   marginTop: theme.spacing(2),
    //   marginBottom: theme.spacing(2),
    // },
  },
}));

const Message = ({ variant, severity, children, title }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          style={{ marginTop: 5, marginBottom: 5 }}
          variant={variant}
          severity={severity}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close fontSize='inherit' />
            </IconButton>
          }
        >
          <AlertTitle>{title}</AlertTitle>
          {children}
        </Alert>
      </Collapse>
    </div>
  );
};

Message.defaultProps = {
  variant: 'filled',
  severity: 'info',
};

export { Message };
