import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const basicTheme = createMuiTheme({
  palette: {
    background: { paper: 'white', default: '#fafafa' },
    primary: {
      main: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      // main: '#B6D77B',
      main: '#C69023',
      // contrastText: '#002A3A',
      contrastText: '#000',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      // secondary: 'rgba(255, 255, 255)',
      // link: 'rgb(164, 194, 111)',
      link: 'rgb(198, 144, 35)',
      secondary: 'rgb(0, 0, 0, 0.38)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1680,
    },
  },
});

export { basicTheme };
