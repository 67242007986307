import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const RouterContext = createContext({
  route: {
    to: '',
    from: '',
  },
});

const RouterContextProvider = (props) => {
  const location = useLocation();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  useEffect(() => {
    setRoute((prev) => ({ to: location.pathname, from: prev.to }));
  }, [location]);

  return (
    <RouterContext.Provider value={{ route: route }}>
      {props.children}
    </RouterContext.Provider>
  );
};

export { RouterContext, RouterContextProvider };
