import React from 'react';

import { makeStyles } from '@material-ui/core';
import { CircularProgress, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const Loader = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='100%'
    >
      <CircularProgress color='secondary' />
    </Box>
  );
};

export { Loader };
