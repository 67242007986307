import axios from 'axios';

export const generateOrderId = async (token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    proxy: {
      host: 'localhost',
      port: 5000,
    },
  };

  const { data } = await axios.get('/api/orders/generateOrderId', config);
  // console.log('data: ', data);
  return data._id;
};
