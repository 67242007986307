import React from 'react';
import LazyLoad from 'react-lazyload';
import YouTube from 'react-youtube';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    // width: '100%',
    borderRadius: 6,
  },
  accordionDetails: {
    flexDirection: 'column',
  },
}));

const LibraryVideos = () => {
  const classes = useStyles();
  const videoIdsSales = [
    '642imZSSorc',
    'ETz5dLnADMY',
    'EQaTO8KAIsg',
    'ZavzEcIYziw',
    'J2cKb5IMBso',
    'VpwRutxY6uc',
    'GmN_JReqi4s',
    '8PpksrAP0cc',
    'x8GbnyE4GoQ',
    'j8pA4hQ7VpI',
    'UH89nKIpww0',
    'ey3XXPvySyQ',
    'qCtG1SZqcTI',
    'hcHHwDJhQb8',
    'rweFw0J9RGM',
    '-VfC62cgmp0',
    'ChdL7Oov41s',
    'F94wQasshiI',
    'Ntsh7pT4McA',
    'v9VPUvQGzGs',
    'pXwy1xKR5YI',
    'z6qZ0yP-02U',
    'Gcbua4yEdfY',
    'wKG4B2NDbkw',
    '2mpcQiLQNvc',
    '8ca836zi3cc',
    'O5fBPswx-cY'
  ];
  const screenVideoIds = [
    '-0LxiPHIGMA',
    '08_s7mdQR2I',
    '288hpW6r-NE',
    'wj9_895jCDQ',
    'SZmG_NhROGU',
    'RrsDBlWJA3o',
    'xp0CPFGKiPU',
    'EXuRRV4GfIA',
    'PiQLTqhBlaA',
    't6yaWjoTXGI',
    'cAZBOd_ISOc'
  ];
  const LRoofsVideoIds = [
    'Ryk5YsAaRUQ',
    'uw0PE0w1vL4',
    'wF5wFiJhcW8',
    'cAZBOd_ISOc'
  ];
  const ThreeSRoofsVideoIds = [
    'p_GejtKWNuM',
    'HHS-fQnnh0E',
    'ZilYa5Wehqo'
  ];
  const ScreenRoomVideoIds = [
    'L24mj8ctej0',
    'cnjWvTGxQ7I',
    'Okxf1xwsQb0',
    'QugTvMeN5II',
    'sN2O3TJBrS8'
  ];
  const marketingScreens = [
    'UDbra_5Pbxo',
    'Mh0fB1mm1m4',
    'ZPOpusruT0k',
    'fb11Dzu28uM',
    'tqhHfYMWi10',
    'J3JMrTbq4yQ',
    'ls5iWMtrsEI',
    'gnoPI7xfffc',
    'aHbnaybhJYU',
    'YBpojAnsOu8',
    '8dmRT4AHK_4',
    '4mcNq8aqu7I',
    'hnLL6RCicao',
    'AkfeHmevq4k'
  ];
  const marketingLRoofs = [
    '6iohbe-HEqs',
    'aafZvN8uBc0',
    '7E_4Ndh-GWw',
    'JVH9OgadJuA',
    'loWspdsSlPs',
    'kByu1wAPD_s',
    'QvDRPxGYQeg'
  ];
  const marketingGeneral = [
    '7PATyepiLMw',
    'nux-SUelmJw',
    'QdY-Y-NByk8',
    'mVmhnTKHZs0'
  ];
  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant='h6' color='textPrimary'>
            Training
          </Typography>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-sales-content'
              id='training-sales-header'
            >
              <Typography>Sales</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {videoIdsSales.map(id => (
                <LazyLoad key={id} height={200} offset={100}>
                  <YouTube videoId={id} />
                </LazyLoad>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-ret-content'
              id='training-ret-header'
            >
              <Typography>Retractable Screens</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {screenVideoIds.map(id => (
                <LazyLoad key={id} height={200} offset={100}>
                  <YouTube videoId={id} />
                </LazyLoad>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-lou-content'
              id='training-lou-header'
            >
              <Typography>Louvered Roofs</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {LRoofsVideoIds.map(id => (
                <LazyLoad key={id} height={200} offset={100}>
                  <YouTube videoId={id} />
                </LazyLoad>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-pr-content'
              id='training-pr-header'
            >
              <Typography>Polycarbonate Roof</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <LazyLoad height={200} offset={100}>
                <YouTube videoId={'5_2SLZKKkmA'} />
              </LazyLoad>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-3sr-content'
              id='training-3sr-header'
            >
              <Typography>3 Season Room</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {ThreeSRoofsVideoIds.map(id => (
                <LazyLoad key={id} height={200} offset={100}>
                  <YouTube videoId={id} />
                </LazyLoad>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='training-sr-content'
              id='training-sr-header'
            >
              <Typography>Screen Room</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {ScreenRoomVideoIds.map(id => (
                <LazyLoad key={id} height={200} offset={100}>
                  <YouTube videoId={id} />
                </LazyLoad>
              ))}
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
      {<Card className={classes.card}>
        <CardContent>
          <Typography gutterBottom variant='h6' color='textPrimary'>
            Sales & Marketing
          </Typography>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-ret-content'
              id='sales-ret-header'
            >
              <Typography>Retractable Screens</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {marketingScreens.map(id => (
                <LazyLoad key={id} height={200} offset={100}>
                  <YouTube videoId={id} />
                </LazyLoad>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-lou-content'
              id='sales-lou-header'
            >
              <Typography>Louvered Roofs</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {marketingLRoofs.map(id => (
                <LazyLoad key={id} height={200} offset={100}>
                  <YouTube videoId={id} />
                </LazyLoad>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-3sr-content'
              id='sales-3sr-header'
            >
              <Typography>3 Season Room</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <LazyLoad height={200} offset={100}>
                <YouTube videoId={'giqeET1m9eE'} />
              </LazyLoad>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-sr-content'
              id='sales-sr-header'
            >
              <Typography>Screen Room</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <LazyLoad height={200} offset={100}>
                <YouTube videoId={'e-75fxS31z4'} />
              </LazyLoad>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-com-content'
              id='sales-com-header'
            >
              <Typography>Commercial</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <LazyLoad height={200} offset={100}>
                <YouTube videoId={'SCy7RUGPrE0'} />
              </LazyLoad>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginTop: 15 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls='sales-gen-content'
              id='sales-gen-header'
            >
              <Typography>General</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {marketingGeneral.map(id => (
                <LazyLoad key={id} height={200} offset={100}>
                  <YouTube videoId={id} />
                </LazyLoad>
              ))}
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>}
    </>
  );
};

export { LibraryVideos };
