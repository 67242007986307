import React, { useContext, useState } from 'react';
// import { useSelector } from 'react-redux';

import { TicketContext } from '../context';

import { Message } from './';

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Link,
  Box,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { AddBox, Pageview, Edit, Cancel } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialogBtn: {
    marginLeft: 'auto',
    marginRight: 5,
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

function TicketDialog({ mode, ticket }) {
  const classes = useStyles();
  const ttx = useContext(TicketContext);

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const [open, setOpen] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    mode === 'edit' || 'view'
      ? ttx.setTicket(ticket)
      : ttx.setTicket(ttx.ticketState);
    setOpen(true);
  };

  const handleClose = () => {
    // ttx.setTicket(ttx.ticketState);
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (
      ttx.ticket.ticketName?.length > 0 &&
      ttx.ticket.ticketMessage.length > 0
    ) {
      ttx.handleCreateTicket(ttx.ticket);
      handleClose();
    } else {
      ttx.setTicket({
        ...ttx.ticket,
        error: 'Please fill in all fields',
      });
    }
  };

  return (
    <>
      {mode === 'edit' ? (
        <Link
          onClick={(e) => handleOpen(e)}
          size='small'
          component='button'
          variant='body2'
        >
          <Edit fontSize='small' />
        </Link>
      ) : mode === 'view' ? (
        <Link
          onClick={(e) => handleOpen(e)}
          size='small'
          component='button'
          variant='body2'
        >
          <Pageview fontSize='small' />
        </Link>
      ) : (
        <Link
          onClick={handleOpen}
          size='small'
          component='button'
          variant='body2'
        >
          <Box
            display='flex'
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AddBox fontSize='small' style={{ marginRight: 2 }} /> Create Ticket
          </Box>
        </Link>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='ticket-dialog-title'
        style={{ minWidth: '450' }}
      >
        <DialogTitle id='ticket-dialog-title'>
          {mode === 'edit' ? 'Edit' : mode === 'view' ? 'View' : 'New'} Ticket
          <IconButton
            onClick={handleClose}
            aria-label='close'
            className={classes.closeBtn}
          >
            <Cancel />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column'>
            <form onSubmit={(e) => handleSave(e)}>
              <Grid item>
                {ttx.ticket?.error && (
                  <Message severity={'error'}>{ttx.ticket.error}</Message>
                )}
                <FormControl required margin='dense'>
                  <InputLabel shrink style={{ fontWeight: 'bold' }}>
                    Ticket Name
                  </InputLabel>
                  <TextField
                    type='text'
                    id='ticketName'
                    required
                    fullWidth
                    autoFocus={mode !== 'view'}
                    disabled={mode === 'view'}
                    value={ttx.ticket?.ticketName}
                    onChange={(e) =>
                      ttx.setTicket({
                        ...ttx.ticket,
                        ticketName: e.target.value,
                      })
                    }
                    margin='dense'
                    InputLabelProps={{
                      shrink: true,
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl required fullWidth margin='dense'>
                  <InputLabel shrink style={{ fontWeight: 'bold' }}>
                    Ticket Type
                  </InputLabel>
                  {mode !== 'view' ? (
                    <Select
                      id='ticketType'
                      required
                      value={ttx.ticket?.ticketType}
                      onChange={(e) =>
                        ttx.setTicket({
                          ...ttx.ticket,
                          ticketType: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={'Support'}>Support</MenuItem>
                      <MenuItem value={'Feedback'}>Feedback</MenuItem>
                      <MenuItem value={'Urgent'}>Urgent</MenuItem>
                    </Select>
                  ) : (
                    <TextField
                      type='text'
                      id='ticketType'
                      required
                      disabled
                      value={ttx.ticket?.ticketType}
                      margin='dense'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl required fullWidth margin='dense'>
                  <InputLabel shrink style={{ fontWeight: 'bold' }}>
                    Ticket Message
                  </InputLabel>
                  <TextField
                    type='text'
                    id='ticketMsg'
                    required
                    disabled={mode === 'view'}
                    multiline
                    minRows={4}
                    value={ttx.ticket?.ticketMessage}
                    onChange={(e) =>
                      ttx.setTicket({
                        ...ttx.ticket,
                        ticketMessage: e.target.value,
                      })
                    }
                    margin='dense'
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl required fullWidth margin='dense'>
                  <InputLabel shrink style={{ fontWeight: 'bold' }}>
                    Ticket Status
                  </InputLabel>
                  {mode !== 'view' ? (
                    <Select
                      id='ticketStatus'
                      required
                      value={ttx.ticket?.ticketStatus}
                      onChange={(e) =>
                        ttx.setTicket({
                          ...ttx.ticket,
                          ticketStatus: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={'New'}>New</MenuItem>
                      <MenuItem value={'In Progress'}>In Progress</MenuItem>
                      <MenuItem value={'Closed'}>Closed</MenuItem>
                    </Select>
                  ) : (
                    <TextField
                      type='text'
                      id='ticketStatus'
                      required
                      disabled
                      value={ttx.ticket?.ticketStatus}
                      margin='dense'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
        <DialogActions>
          {mode !== 'view' && (
            <Button
              onClick={(e) => handleSave(e)}
              variant='contained'
              type='submit'
              color='primary'
              size='small'
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export { TicketDialog };
