// file upload constants
export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';

// file list constants
export const FILE_LIST_REQUEST = 'FILE_LIST_REQUEST';
export const FILE_LIST_SUCCESS = 'FILE_LIST_SUCCESS';
export const FILE_LIST_FAILURE = 'FILE_LIST_FAILURE';

// file delete constants
export const FILE_DELETE_REQUEST = 'FILE_DELETE_REQUEST';
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS';
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE';

// file download constants
export const FILE_DOWNLOAD_REQUEST = 'FILE_DOWNLOAD_REQUEST';
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS';
export const FILE_DOWNLOAD_FAILURE = 'FILE_DOWNLOAD_FAILURE';
