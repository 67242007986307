import React, { useContext } from 'react';

import { StoreContext } from '../context';

import { Button, ButtonGroup } from '@material-ui/core';

const StoreCardButtons2 = ({ item }) => {
  const ctx = useContext(StoreContext);

  const itemId = item[4];
  const title = item[1];
  const price = item[3];

  const handleAddToCart = (e, itemId, title, price) => {
    ctx.handleAddPrtToCart(e, itemId, title, 1, price);
  };

  const isDisabled = () => {
    let flag;
    if (ctx.isOrder) {
      if (ctx.cartInfo.status === 'Draft') {
        flag = false;
      } else if (ctx.cartInfo.status === 'Submitted' && ctx.isAdmin) {
        flag = false;
      } else {
        flag = true;
      }
    } else {
      flag = false;
    }
    return flag;
  };

  return (
    <ButtonGroup size='small' aria-label='small outlined button group'>
      <Button
        variant='contained'
        color='primary'
        disabled={isDisabled()}
        onClick={(e) => handleAddToCart(e, itemId, title, price)}
      >
        Add
      </Button>
    </ButtonGroup>
  );
};

export { StoreCardButtons2 };
