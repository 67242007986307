// login constants
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

// get user
export const GET_USER = 'GET_USER'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'

// register constants
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_LOGOUT = 'USER_REGISTER_LOGOUT';

// register constants
export const USER_RESET_REQUEST = 'USER_RESET_REQUEST';
export const USER_RESET_SUCCESS = 'USER_RESET_SUCCESS';
export const USER_RESET_FAIL = 'USER_RESET_FAIL';
export const USER_RESET_LOGOUT = 'USER_RESET_LOGOUT';
