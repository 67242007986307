import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { uploadFiles, listFiles, deleteFile } from '../actions';
import { Loader, Message } from '../components';

import {
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  withStyles,
  Link,
  Typography,
  DialogContentText,
  makeStyles,
} from '@material-ui/core';
import { DeleteForever, Cancel } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingLeft: theme.spacing(1),
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  bullet: {
    marginRight: theme.spacing(1),
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  selectBtn: {},
  uploadBtn: {},
  cancelBtn: {},
}));

const ErrorIconButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))(IconButton);

const FileDialog = ({ dealerName, orderId }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileList = useSelector((state) => state.fileList);
  const { files, loading, error } = fileList;

  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  // // console.log('FileDialog: files', files);
  // // console.log('FileDialog: selectedFiles', selectedFiles);

  useEffect(() => {
    if (open) {
      dispatch(listFiles('orders', dealerName, orderId));
    }
  }, [open, dispatch, dealerName, orderId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    setSelectedFiles([...selectedFiles, ...e.target.files]);
  };

  const handleFileUpload = (e) => {
    try {
      dispatch(uploadFiles('orders', dealerName, orderId, selectedFiles)).then(
        () => {
          dispatch(listFiles('orders', dealerName, orderId));
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
        }
      );
      setSelectedFiles([]);
    } catch (error) {
      setSelectedFiles((prevFiles) =>
        prevFiles.filter((file) => !selectedFiles.includes(file))
      );
    }
    // console.log('FileDialog: selectedFiles', selectedFiles);
  };

  const handleFileRemove = (fileToRemove) => {
    setSelectedFiles(selectedFiles.filter((file) => file !== fileToRemove));
  };

  const handleFileDelete = (fileName) => {
    dispatch(deleteFile('orders', dealerName, orderId, fileName)).then(() => {
      dispatch(listFiles('orders', dealerName, orderId));
    });
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const orderFiles = files?.orders.sort((a, b) => (a.Key > b.Key ? 1 : -1));

  // // console.log('FileDialog: files', orderFiles);

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleClickOpen}
        style={{ marginRight: 5 }}
      >
        Files
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          File Manager
          <IconButton
            onClick={handleClose}
            aria-label='close'
            className={classes.closeBtn}
          >
            <Cancel />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText variant='subtitle2'>
            To upload files, select one or more files using the "Select Files"
            button below. Once files have been selected, click "Upload" to
            upload them. Any files that have been uploaded for this order will
            be listed below.
          </DialogContentText>
          {selectedFiles.length > 0 && (
            <Message severity='info' title='Files selected for upload'>
              <List dense className={classes.list}>
                {selectedFiles.map((file) => (
                  <ListItem key={file.name} className={classes.listItem}>
                    <ListItemText
                      primary={
                        <>
                          <span className={classes.bullet}>&bull;</span>
                          {file.name}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        color='inherit'
                        edge='end'
                        onClick={() => handleFileRemove(file)}
                      >
                        <DeleteForever />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Message>
          )}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message severity='error'>{error}</Message>
          ) : (
            <Card hidden={!orderFiles || !orderFiles.length}>
              <CardContent>
                <List
                  dense
                  subheader={
                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                      Files
                    </Typography>
                  }
                >
                  {orderFiles &&
                    orderFiles?.map((file, i) => (
                      <ListItem key={file.key + i}>
                        <ListItemText>
                          {file.name ? (
                            <Link
                              href={file.signedUrl}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {file.name}
                            </Link>
                          ) : null}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <ErrorIconButton
                            edge='end'
                            onClick={() => handleFileDelete(file.name)}
                          >
                            <DeleteForever />
                          </ErrorIconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
              </CardContent>
            </Card>
          )}
        </DialogContent>
        <DialogActions>
          <input
            type='file'
            hidden
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <Button
            variant='contained'
            color='primary'
            onClick={handleButtonClick}
            size='small'
            className={classes.selectBtn}
          >
            Select File(s) to Upload
          </Button>
          <Button
            onClick={(e) => handleFileUpload(e)}
            variant='contained'
            color='primary'
            size='small'
            disabled={!selectedFiles.length || loading}
            className={classes.uploadBtn}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { FileDialog };
