// SCREEN KIT MAPPER
import { 
  getHardwareKit, 
  getWidthKit, 
  getZipperCKit,
  getZipperMKit, 
  getBottomBarKit,
  getMatieralKit, 
  getAngleKit, 
  getMotorKit, 
  getLeftTrackKit, 
  getRightTrackKit 
} from './kitMapperUtils';
const scrKitMapper = (screen, kits, portalPricings) => {
const getExtraPricing = (priceName, price) => {
  const ppVinylMethod = portalPricings.find((pp) =>
    pp.portalPricingName.includes(priceName)
  ).portalPricingMethod;
  const ppVinylValue = portalPricings.find((pp) =>
    pp.portalPricingName.includes(priceName)
  ).portalPricingValue;

  switch (ppVinylMethod) {
    case '+':
      price += ppVinylValue;
      break;
    case '-':
      price -= ppVinylValue;
      break;
    case '*':
      price *= ppVinylValue;
      break;
    case '/':
      price /= ppVinylValue;
      break;
    default:
      break;
  }
  return price
}
  let mappedScrKits = {}
  let pricedScreenKits = {}
  if (!screen.meshOnly) {
    const hardwareKit = getHardwareKit(screen, kits) 
  const widthKit = getWidthKit(screen, kits) 
  const zipperMKit = getZipperMKit(screen, kits) 
  const zipperCKit = getZipperCKit(screen, kits) 
  const bottomBarKit = getBottomBarKit(screen, kits) 
  const materialKit = getMatieralKit(screen, kits) 
  const angleKit = getAngleKit(screen, kits) 
  const motorKit = getMotorKit(screen, kits) 
  const leftTrackKit = getLeftTrackKit(screen, kits) 
  const rightTrackKit = getRightTrackKit(screen, kits) 

  let materialPrice = materialKit.totalPrice
  let leftTrackPrice = leftTrackKit.customerPrice
  let rightTrackPrice = rightTrackKit.customerPrice
  const height = (Math.max(screen.measurements.dropLeft, screen.measurements.dropRight))
  if(screen.options.materialType === 'Vinyl' || screen.options.materialType === 'Weblon'){
   materialPrice = getExtraPricing('Vinyl/Weblon Multiplier', materialKit.totalPrice)
  }
  if(screen.options["Track Left"] === "Cable Track" && height > 132){
    leftTrackPrice = getExtraPricing('Cable Track Addition', leftTrackKit.customerPrice)
   }
   if(screen.options["Track Right"] === "Cable Track" && height > 132){
    rightTrackPrice = getExtraPricing('Cable Track Addition', rightTrackKit.customerPrice)
   }


    mappedScrKits = {
    BOMS: [
      hardwareKit,
      widthKit,
      motorKit,
      leftTrackKit,
      rightTrackKit,
    ],
    items:[
      zipperMKit,
      zipperCKit,

      bottomBarKit,
      materialKit,
      angleKit,
  ]
 
  }
    pricedScreenKits =
      Number(hardwareKit.customerPrice) +
      Number(widthKit.totalPrice)  +
      Number(zipperCKit.totalPrice) +
      Number(zipperMKit.totalPrice) +
      Number(bottomBarKit.totalPrice) +
      Number(materialPrice) +
      Number(angleKit.totalPrice) +
      Number(motorKit.customerPrice) +
      Number(leftTrackPrice) +
      Number(rightTrackPrice) 

      pricedScreenKits = getExtraPricing('Screen Kit Addition', pricedScreenKits)
      if(screen.options.jobColor === "MF"){
        pricedScreenKits = getExtraPricing('Custom Color Multiplier', pricedScreenKits)
      }



  } else {
    const meshKit = getMatieralKit(screen, kits) 
    mappedScrKits = {BOMS:[], items: [meshKit]};
    const ppMeshOnlyMethod = portalPricings.find((pp) =>
      pp.portalPricingName.includes('Material Only')
    ).portalPricingMethod;
    const ppMeshOnlyValue = portalPricings.find((pp) =>
      pp.portalPricingName.includes('Material Only')
    ).portalPricingValue;
    pricedScreenKits = Number(meshKit.totalPrice) 

    switch (ppMeshOnlyMethod) {
      case '+':
        pricedScreenKits += ppMeshOnlyValue;
        break;
      case '-':
        pricedScreenKits -= ppMeshOnlyValue;
        break;
      case '*':
        pricedScreenKits *= ppMeshOnlyValue;
        break;
      case '/':
        pricedScreenKits /= ppMeshOnlyValue;
        break;
      default:
        break;
    }
  }

  if (screen.options.materialType === 'Vinyl' || screen.options.materialType === 'Weblon') {
    pricedScreenKits = getExtraPricing('Vinyl/Weblon Addition', pricedScreenKits)
    
  }
  // console.log('screenKits', kits);
  // console.log('mappedScrKits', mappedScrKits);
  console.log('pricedScreenKits', pricedScreenKits);

  return {
    screenName: screen.screenName,
    screenId: screen.screenId,
   motorSide: screen.motorSide,
    details: screen.details,
    screenWidth: screen.measurements.width,
    dropLeft: screen.measurements.dropLeft,
    dropRight: screen.measurements.dropRight,
    mappedScrKits: mappedScrKits,
    price: pricedScreenKits,
  };
};



export { scrKitMapper };
