import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';

import { logout } from '../actions';
import { Loader, Message } from '../components';

import { Button, Grid, makeStyles } from '@material-ui/core';

import Image from '../assets/images/home-img-cropped.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  card: {
    margin: 'auto',
    minHeight: '70vh',
    // borderRadius: 4,
    // direction: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  navLinks: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));

const HomeView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;
  const history = useHistory();

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  useEffect(() => {
    if (!userInfo || isTokenExpired(userInfo.token)) {
      dispatch(logout());
      history.push('/login');
    }
  }, [history, userInfo, dispatch]);

  return (
    <>
      <h1>Home</h1>
      <div className={classes.root}>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message severity='error'>{error}</Message>
        ) : !userInfo?.isApproved ? (
          <Message severity='info'>
            Thanks for logging in, we are waiting for your account to be
            approved.
          </Message>
        ) : (
          <Grid
            container
            item
            direction='column'
            alignContent='center'
            justifyContent='center'
            className={classes.card}
          >
            {userInfo?.storeAccess && (
              <Button
                className={classes.navLinks}
                variant='contained'
                color='primary'
                component={RouterLink}
                to='/store'
              >
                Store
              </Button>
            )}
            <Button
              className={classes.navLinks}
              variant='contained'
              color='primary'
              component={RouterLink}
              to='/docs'
            >
              Library
            </Button>
            <Button
              className={classes.navLinks}
              variant='contained'
              color='primary'
              component={RouterLink}
              to='/account'
            >
              Account
            </Button>
            {userInfo && userInfo.isAdmin && (
              <Button
                className={classes.navLinks}
                variant='contained'
                color='secondary'
                component={RouterLink}
                to='/admin'
              >
                Admin
              </Button>
            )}
          </Grid>
        )}
      </div>
    </>
  );
};

export { HomeView };
