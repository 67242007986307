import React, { useEffect, useState, useContext } from 'react';
import Fuse from 'fuse.js';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { StoreContext } from '../context';
import '../assets/css/store.css';
import { Message, Loader } from '../components';
import lunr from 'lunr';

import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  makeStyles,
  Button,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  sortedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  numberInput: {
    '& input[type=number]': {
      '-webkit-appearance': 'textfield',
      '-moz-appearance': 'textfield',
      appearance: 'textfield',
      outline: 'none',
    },
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'inner-spin-button',
      opacity: 1,
      display: 'block',
    },
    '&:focus-within input[type=number]': {
      borderColor: '#3f51b5',
    },
  },
  header: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
  },
  collapsedHeader: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    fontWeight: 'bold',
  },
  sortedColumn: {
    backgroundColor: '#f5f5f5',
  },
  column: {},
  sortIcon: {
    fontSize: '1rem',
    verticalAlign: 'middle',
    marginLeft: '0.25rem',
  },
  expandIcon: {
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  textLink: {
    color: theme.palette.text.link,
    cursor: 'pointer',
  },
}));

const StoreParts2 = ({ classes }) => {
  const classes2 = useStyles();

  const ctx = useContext(StoreContext);
  const {
    portalItemsLoading,
    portalItemsError,
    portalParts,
    portalCategories,
    bcParts,
    handleAddPrtToCart,
    cartInfo
  } = ctx;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;


  // console.log('portalCategories', portalCategories);

  const [portalPartsNameFilter, setPortalPartsNameFilter] = useState('');
  const [portalPartsCategoryFilter, setPortalPartsCategoryFilter] = useState('');
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [partQuantities, setPartQuantities] = useState({});
  const [visibleAttributes, setVisibleAttributes] = useState({});
  const [displayPrices, setDisplayPrices] = useState({});
  const [portalPartsFiltered, setPortalPartsFiltered] = useState(portalParts);
  const [truePortalParts, setTruePortalParts] = useState([]);


  const categories = [
    { label: '3 Season', value: '3S' },
    { label: 'Accessories', value: 'ACC' },
    { label: 'Fixed Screen', value: 'FS' },
    { label: 'Hardware', value: 'HW' },
    { label: 'Louvered Roof', value: 'LR' },
    { label: 'Motors and Controls', value: 'MC' },
    { label: 'Mesh and Vinyl', value: 'MV' },
    { label: 'Other', value: 'OTH' },
    { label: 'Polycarbonate Roof', value: 'PC' },
    { label: 'Retractable Screen', value: 'RS' },
    { label: 'Sales and Marketing', value: 'SM' }
  ];
  const idx = lunr(function () {
    this.pipeline.remove(lunr.stemmer); // Disable stemming
  this.searchPipeline.remove(lunr.stemmer); // Disable stemming in search queries
    this.field('displayName2');
    portalParts?.forEach((part, i) => {
      this.add({ ...part, id: i });
    });
  });
const filterParts = () => {
  if (portalParts && Array.isArray(portalParts)) {
    let portalPartsIn = portalParts;
    portalPartsIn = portalPartsIn.filter((part) => part?.displayName2 !== '');
    if (portalPartsNameFilter || portalPartsCategoryFilter) {
      if (portalPartsNameFilter) {
        const results = idx.search(`*${portalPartsNameFilter}*`);
        portalPartsIn = results.map(result => portalParts[result.ref]);
      }
      if (portalPartsCategoryFilter) {
        portalPartsIn = portalPartsIn.filter(
          (part) => part?.itemCategoryCode === portalPartsCategoryFilter
        );
      }
    }
    const categoryOrder = categories.reduce((acc, cat, index) => {
      acc[cat.value] = index;
      return acc;
    }, {});

    portalPartsIn.sort((a, b) => {
      const categoryA = categoryOrder[a.itemCategoryCode] ?? Infinity;
      const categoryB = categoryOrder[b.itemCategoryCode] ?? Infinity;

      if (categoryA === categoryB) {
        return a.displayName2.localeCompare(b.displayName2);
      }
      return categoryA - categoryB;
    });
    setPortalPartsFiltered(portalPartsIn);
  } else {
    setPortalPartsFiltered(portalPartsFiltered)
  }
}
  useEffect(() => { 
    portalParts?.forEach(part => setDisplayPrices((prevPrices) => ({
      ...prevPrices,
      [part.displayName2]:  findRelevantPrice(part.displayName2),
    })))
    
    //setPortalPartsFiltered(portalParts)
    filterParts()

  }, [portalPartsNameFilter, portalPartsCategoryFilter, portalParts, selectedAttributes]);
  useEffect(() => {
    if(portalParts && truePortalParts.length === 0){
      setTruePortalParts(portalParts)
    }
  }, [portalParts]); 

  // const twoby8s = portalParts?.filter((part) => {
  //   return part?.displayName2?.toLowerCase() === '2x8';
  // });

  // console.log('twoby8s', twoby8s);

  // let portalCats = portalCategories?.filter((pc) => {
  //   return !pc.toLowerCase().includes('PK-');
  // });


  const filterNextAttributeOptions = (displayName2, selectedAttributeName, selectedAttributeValue) => {
    const matchingBcParts = bcParts.filter((bcPart) => 
      bcPart.displayName2 === displayName2 && 
      bcPart.attributes.some((attr) => attr.attributeName === selectedAttributeName && attr.attributeValue === selectedAttributeValue)
    );
    const validAttributes  = {}
     matchingBcParts.forEach(item => {
      item.attributes.forEach(attribute => {
        if (!validAttributes[attribute.attributeName]) {
          validAttributes[attribute.attributeName] = [];
        }
        if (!validAttributes[attribute.attributeName].includes(attribute.attributeValue)) {
          validAttributes[attribute.attributeName].push(attribute.attributeValue)
        }
      });
    });
    validAttributes[selectedAttributeName] = new Set();
    bcParts.forEach(obj => {
      obj.attributes.forEach(attribute => {
          if ( obj.displayName2 === displayName2 && attribute.attributeName === selectedAttributeName) {
            validAttributes[selectedAttributeName].add(attribute.attributeValue);
          }
      });
  });
  validAttributes[selectedAttributeName] =  Array.from(validAttributes[selectedAttributeName]);
    const itemToUpdate = portalPartsFiltered.find(item => item.displayName2 === displayName2);
    if (itemToUpdate) {
      const updatedAttributes = Object.keys(validAttributes).map(attributeName => ({
        attributeName: attributeName,
        attributeValues: validAttributes[attributeName]
      }));
      setPortalPartsFiltered((prevPortalParts) =>
        prevPortalParts.map((part) => {
          if (part.displayName2 === displayName2) {
            portalParts.find(part => part.displayName2 ===  displayName2).attributes = updatedAttributes
            return {
              ...part,
              attributes: updatedAttributes
            };
          }
          return part;
        })
      );

    }

  };
  const handleAttributeChange = (displayName2, attributeName, attributeValue) => {
      setSelectedAttributes((prevAttributes) => ({
      ...prevAttributes,
      [displayName2]: {
        ...(prevAttributes[displayName2] || {}),
        [attributeName]: attributeValue,
      },
    }));
    
    setVisibleAttributes((prev) => ({
      ...prev,
      [displayName2]: (prev[displayName2] || 1) + 1,
    }));
    filterNextAttributeOptions(displayName2, attributeName, attributeValue);
  };

const findRelevantPrice = (displayName2) => {
  let part  = portalParts.find((i) => i.displayName2 === displayName2)
  if(!isAddButtonDisabled(part) && displayName2 !== ''){
    let bcPart = findRelevantPart(displayName2)
    let filteredPrice = bcPart?.prices.find(price => price.salesCode === userInfo.bc_customer_priceGroup)
    if(filteredPrice){
      return "$" + (bcPart?.prices.find(price => price.salesCode === userInfo.bc_customer_priceGroup).unitPrice * userInfo.bc_customer_tierRate).toFixed(2)
    } else{
      return ''
    }
  } else{
    return ''
  }
}
  const handleQuantityChange = (displayName2, quantity) => {
    setPartQuantities((prevQuantities) => ({
      ...prevQuantities,
      [displayName2]: quantity,
    }));
  };

  const findRelevantPart = (displayName2) => {
    const attributes = selectedAttributes[displayName2] ? selectedAttributes[displayName2] : {};

    return bcParts?.find((bcPart) => {

      if (bcPart.displayName2 !== displayName2) return false;
      return Object.entries(attributes).every(([attributeName, value]) => {
        return bcPart.attributes.some(
          (attr) =>
            attr.attributeName === attributeName &&
            attr.attributeValue === value
        );
      });
    });
  };

  const addToCartHandler = (part) => {
    const quantity = partQuantities[part.displayName2];
    if (!quantity || quantity === 0) {
      toast.error('Please enter a quantity');
      return;
    }
    let missingAttribute = isAddButtonDisabled(part)
    if (missingAttribute) {
      toast.error('Please enter all options');
      return;
    }
    const relevantPart = findRelevantPart(part.displayName2);
    portalParts.find(portalPart => portalPart.displayName2 === part.displayName2).attributes = truePortalParts.find(portalPart => portalPart.displayName2 === part.displayName2).attributes

    if (relevantPart) {
      handleAddPrtToCart(
        relevantPart._id,
        relevantPart.number,
        relevantPart.displayName,
        quantity,
        relevantPart.prices,
        false
      );
      setSelectedAttributes((prevAttributes) => ({
        ...prevAttributes,
        [part.displayName2]: {
        },
      }))
      setPartQuantities((prevAttributes) => ({
        ...prevAttributes,
        [part.displayName2]: 0,
      }));
      setVisibleAttributes((prev) => ({
        ...prev,
        [part.displayName2]: 1,
      }));
    } else {
      toast.error('That option combination is not allowed');
      return;
    }
  };
  const isAddButtonDisabled = (part) => {
    const selectedAttributesForPart = selectedAttributes?.[part.displayName2] || {};
    return part.attributes.some((attribute) => {
      if (attribute.attributeName === 'Portal Kit' || attribute.attributeName === 'Roof Kit' || attribute.attributeName.startsWith('Kit-')) {
        return false;
      }
      return !selectedAttributesForPart[attribute.attributeName];
    });
  };

  return portalItemsLoading ? (
    <Loader />
  ) : portalItemsError ? (
    <Message severity='error'>{portalItemsError}</Message>
  ) : (
    <Grid style={{ marginLeft: 25, marginRight: 25 }}container spacing={1}>
      <Grid  item xs={12} >
        <Grid container spacing={2} justifyContent='center'  wrap='wrap' alignItems='center'>
          <Grid container item xs={12} direction='row'>
          <Grid style={{ marginTop: 15 }} item xs={3}>
              <TextField
                label='Filter by Category'
                value={portalPartsCategoryFilter}
                onChange={(e) => setPortalPartsCategoryFilter(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontVariant: 'normal',
                    color: 'rgba(0, 0, 0, 0.75)',
                  },
                }}
                style={{ marginBottom: 10, marginTop: -15 }}
                size='small'
                select
                fullWidth
              >
                <MenuItem value=''>All</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid style={{ marginTop: 15 }} item xs={3}>
              <TextField
                label='Search by Part Name'
                value={portalPartsNameFilter}
                onChange={(e) => setPortalPartsNameFilter(e.target.value)}
                InputLabelProps={{
                  style: {
                    fontVariant: 'normal',
                    color: 'rgba(0, 0, 0, 0.75)',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {portalPartsNameFilter && (
                        <IconButton
                          aria-label='clear filter'
                          edge='end'
                          onClick={() => setPortalPartsNameFilter('')}
                          size='small'
                        >
                          <Clear fontSize='small' color='error' />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: 10, marginTop: -15, marginLeft: 10 }}
                size='small'
              />
            </Grid>

            <Grid item xs={6}></Grid>
          </Grid>
          <Grid item xs={2} style={{ paddingLeft: 8 }} className={classes2.header}>
            Category
          </Grid>
          <Grid item xs={2} style={{ paddingLeft: 4 }} className={classes2.header}>
            Part Name
          </Grid>
          <Grid item xs={5} style={{ paddingRight: 15, paddingLeft: 15 }} className={classes2.header}>
            Options
          </Grid>
          <Grid item xs={1} style={{ paddingLeft: 4 }} className={classes2.header}>
            Price
          </Grid>
          <Grid
            item
            container
            xs={2}
            style={{ paddingLeft: 4 }}
            alignItems='center'
            className={classes2.header}
          >
            Quantity
          </Grid>

        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
   
      >
        {portalPartsFiltered &&
          portalPartsFiltered.map((part) => (
            <Grid
              container
              spacing={1}
              key={part?._id}
              wrap='wrap'
              alignItems='flex-end'
              className="partsAttributes"
              justifyContent='center'
              style={{ marginTop: 30, alignItems: "center" }}
            >
              <Grid item xs={2}>

                <Typography variant='body1'><strong>{categories.find(cat => cat.value === part?.itemCategoryCode)?.label || part?.itemCategoryCode}</strong> </Typography>

              </Grid>
              <Grid item xs={2}>
                <Typography variant='body1'>{part?.displayName2}</Typography>
              </Grid>
              <Grid style={{ paddingRight: 15, paddingLeft: 15, minHeight: 60}} item container xs={5}direction='row'>
                {part &&
                  part?.attributes
                    ?.filter(
                      (attribute) => attribute?.attributeName !== 'Portal Kit'
                    )
                    ?.filter(
                      (attribute) => attribute?.attributeName !== 'Roof Kit'
                    )

                    ?.filter(
                      (attribute) => !attribute?.attributeName.startsWith('Kit-')
                    )
                    .slice(0, visibleAttributes[part.displayName2] || 1).map((attribute) => (

                      <Grid style={{minHeight: 60}} item xs={4} key={attribute?.attributeName}>
                        <TextField
                          select
                          label={attribute?.attributeName}
                          defaultValue={attribute?.attributeValues[0]}
                          InputLabelProps={{
                            style: {},
                          }}
                          InputProps={{
                            style: {
                                 width: "90px",
                            },
                          }}
                          sx={{
                            minWidth: 150, // Set a minimum width for dropdowns
                            maxWidth: 250, // Adjust the max-width as needed
                          }}
                          value={
                            (selectedAttributes?.[part.displayName2]?.[
                              attribute.attributeName
                            ] || '')
                          }
                          fullWidth
                          size='small'
                          onChange={(e) =>
                            handleAttributeChange(
                              part?.displayName2,
                              attribute?.attributeName,
                              e.target.value
                            )
                          }
                        >
                          {attribute &&
                            attribute?.attributeValues?.map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    )
                    )}
              </Grid>
              <Grid style={{ textAlign: 'right', paddingRight: 10, paddingLeft: 0 }} item xs={1}>
             <Typography style={{ paddingBottom: 4 }} variant='body1'> {displayPrices[part.displayName2]}</Typography>
              </Grid>
              <Grid
                item
                container
                xs={2}
                alignItems='center'
                direction='row'
                justifyContent='center'
     
              >
                <Grid item>
                  <TextField
                    id={'outlined-number' + part?._id}
                    type='number'
                    // variant='outlined'
                    size='small'
                    required
                    inputProps={{ min: 0 }}
                    InputProps={{ classes: { root: classes2.numberInput } }} // Apply custom styles
                    style={{ width: 45, textAlign: 'right',  marginRight: 15}}
                    value={partQuantities[part.displayName2] || 0}
                    onChange={(e) =>
                      handleQuantityChange(part.displayName2, e.target.value)
                    }
                  />
                </Grid>
                <Grid item>
                  <Button
                    size='small'
                    variant='contained'
                    color='primary'
                    onClick={() => addToCartHandler(part)}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export { StoreParts2 };