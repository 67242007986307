import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui imports
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  TextField,
  Typography,
  Link,
  Avatar,
} from '@material-ui/core';

// component imports
import { Message, Loader } from '../components';
// action imports
import { register } from '../actions';
import { LockOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const RegisterView = ({ location, history }) => {
  const classes = useStyles();

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(null);
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, registerInfo } = userRegister;

  const redirect = location.search ? location.search.split('=')[1] : '/';

  useEffect(() => {
    if (registerInfo) {
      history.push(redirect);
    }
  }, [history, registerInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
    } else {
      dispatch(register(fname, lname, email, password));
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar style={{ margin: 1, backgroundColor: '#B6D77B' }}>
          <LockOutlined />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Register
        </Typography>
        {loading ? (
          <Loader />
        ) : (
          <>
            {error && <Message severity='error'>{error}</Message>}
            {message && <Message severity='info'>{message}</Message>}
            <form className={classes.form} onSubmit={submitHandler}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id='registerfname'
                    name='registerfname'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      autoComplete: 'no',
                    }}
                    label='First Name'
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    required
                    fullWidth
                    autoFocus
                    variant='outlined'
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id='registerlname'
                    name='registerlname'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      autoComplete: 'no',
                    }}
                    label='Last Name'
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    required
                    fullWidth
                    autoFocus
                    variant='outlined'
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='registeremail'
                    name='registeremail'
                    type='email'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      autoComplete: 'no',
                    }}
                    label='Email Address'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                    autoFocus
                    variant='outlined'
                    margin='normal'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id='registerpassword'
                    name='registerpassword'
                    type='password'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      autoComplete: 'no',
                    }}
                    label='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                    autoFocus
                    variant='outlined'
                    margin='normal'
                  />
                  <TextField
                    id='registerconfirmpassword'
                    name='registerconfirmpassword'
                    type='password'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      autoComplete: 'no',
                    }}
                    label='Confirm Password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    fullWidth
                    autoFocus
                    variant='outlined'
                    margin='normal'
                  />
                </Grid>
                <Button
                  className={classes.submit}
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                >
                  Sign Up
                </Button>
                <Grid container justifyContent='flex-end'>
                  <Grid item>
                    <Link component={RouterLink} to='/login'>
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </div>
    </Container>
  );
};

export { RegisterView };
