import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // display: 'flex',
    // minHeight: '100vh',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // maxWidth: '1280px', // change this as needed
    // margin: 'auto',
  },
}));

const SiteContainer = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export { SiteContainer };
