import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logout, listFiles } from '../actions';
import { LibraryDocs, LibraryVideos, LibraryPhotos } from '../components';

import {
  AppBar,
  Tab,
  Tabs,
  Box,
  makeStyles,
  withStyles,
  Grid,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledBox = withStyles((theme) => ({
  root: {
    padding: 15,
  },
}))(Box);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <StyledBox p={3}>{children}</StyledBox>}
    </div>
  );
}

const LibraryDocsTab = ({ value }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === 0) {
      dispatch(listFiles('docs'));
    }
  }, [dispatch, value]);

  return (
    <div className={classes.root}>
      <TabPanel value={value} index={0}>
        <h2>Document Library</h2>
        <Grid
          container
          item
          md={12}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <LibraryDocs />
        </Grid>
      </TabPanel>
    </div>
  );
};

const LibraryVideosTab = ({ value }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TabPanel value={value} index={1}>
        <h2>Video Library</h2>
        <Grid
          container
          item
          md={12}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <LibraryVideos />
        </Grid>
      </TabPanel>
    </div>
  );
};

const LibraryPhotosTab = ({ value }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === 2) {
      dispatch(listFiles('photos'));
    }
  }, [dispatch, value]);

  return (
    <div className={classes.root}>
      <TabPanel value={value} index={2}>
        <h2>Photos Library</h2>
        <Grid
          container
          item
          md={12}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <LibraryPhotos />
        </Grid>
      </TabPanel>
    </div>
  );
};

const LibraryView = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // check if user is logged in
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  function isTokenExpired(token) {
    const expiry = JSON.parse(Buffer.from(token.split('.')[1], 'base64')).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  useEffect(() => {
    if (!userInfo || isTokenExpired(userInfo.token)) {
      dispatch(logout());
      history.push('/login');
    }
  }, [history, userInfo, dispatch]);

  return (
    <>
      <h1>Library</h1>
      <AppBar position='static'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='simple tabs example'
        >
          <Tab label='Documents' {...a11yProps(0)} />
          <Tab label='Videos' {...a11yProps(1)} />
          <Tab label='Photos' {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <LibraryDocsTab value={value} />
      <LibraryVideosTab value={value} />
      <LibraryPhotosTab value={value} />
    </>
  );
};

export { LibraryView };
