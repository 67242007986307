import { useContext } from 'react';
import { format } from 'date-fns';

import { TicketContext } from '../context';

import { Loader, Message, TicketDialog } from './';

import {
  // Grid,
  // Typography,
  // FormControl,
  // TextField,
  // Button,
  // Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';

const TicketListAdmin = () => {
  const ttx = useContext(TicketContext);
  const adminTicketsLoading = ttx.adminTicketsLoading;
  const adminTickets = ttx.adminTickets?.sort((a, b) =>
    a.updatedAt > b.updatedAt ? -1 : 1
  );
  const adminTicketsNew = adminTickets?.filter(
    (ticket) => ticket.ticketStatus.toLowerCase() === 'new'
  );
  const adminTicketsProgress = adminTickets?.filter(
    (ticket) => ticket.ticketStatus.toLowerCase() === 'in progress'
  );
  const adminTicketsOpen = adminTickets?.filter((ticket) => ticket.isOpen);
  const adminTicketsClosed = adminTickets?.filter(
    (ticket) => ticket.ticketStatus.toLowerCase() === 'closed'
  );

  const adminTicketsError = ttx.adminTicketsError;

  return adminTicketsLoading ? (
    <Loader />
  ) : adminTicketsError ? (
    <Message severity='error'>{adminTicketsError}</Message>
  ) : (
    <TableContainer component={Paper}>
      <Table aria-label='adminTickets table'>
        <TableHead>
          <TableRow>
            <TableCell align='right' colSpan={9}>
              <Typography variant='subtitle2'>
                {`New: ${adminTicketsNew?.length}${' | '}
                In Progress: ${adminTicketsProgress?.length}`}
              </Typography>
              <Typography variant='subtitle2'>
                {`Open: ${adminTicketsOpen?.length}${' | '}
                Closed: ${adminTicketsClosed?.length}`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>Date Submitted</TableCell>
            <TableCell align='left'>Dealer Name</TableCell>
            <TableCell align='left'>Portal User</TableCell>
            <TableCell align='left'>Price Group</TableCell>
            <TableCell align='left'>Ticket Type</TableCell>
            <TableCell>Ticket Name</TableCell>
            <TableCell>Ticket Status</TableCell>
            <TableCell align='left'>Date Updated</TableCell>
            <TableCell align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adminTickets && adminTickets.length > 0 ? (
            adminTickets.map((ticket) => (
              <TableRow key={ticket._id}>
                <TableCell align='left'>
                  {format(new Date(ticket.createdAt), 'MMM dd, yyyy')}
                </TableCell>
                <TableCell>
                  {ticket.userDetails.bc_customer_displayName}
                </TableCell>
                <TableCell>{ticket.userDetails.userName}</TableCell>
                <TableCell>
                  {ticket.userDetails.bc_customer_priceGroup}
                </TableCell>
                <TableCell>{ticket.ticketType}</TableCell>
                <TableCell>{ticket.ticketName}</TableCell>
                <TableCell>{ticket.ticketStatus}</TableCell>
                <TableCell align='left'>
                  {format(new Date(ticket.updatedAt), 'MMM dd, yyyy')}
                </TableCell>
                <TableCell align='center'>
                  <TicketDialog mode='edit' ticket={ticket} />
                  <TicketDialog mode='view' ticket={ticket} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align='center'>
                <h3>No orders to display.</h3>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { TicketListAdmin };
