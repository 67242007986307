import React from 'react';
import { useSelector } from 'react-redux';

import { Loader, Message } from '../components';

import FileBrowser from 'react-keyed-file-browser';
import 'react-keyed-file-browser/dist/react-keyed-file-browser.css';
import '../assets/css/libraryDocs.css';

import { Card, CardContent, makeStyles } from '@material-ui/core';
import {
  GetApp,
  Image,
  PictureAsPdf,
  Folder,
  FolderOpen,
  Cached,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    borderRadius: 6,
  },
  icon: {
    position: 'relative',
    top: theme.spacing(),
    paddingRight: 5,
  },
}));

const LibraryDocs = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const fileList = useSelector((state) => state.fileList);
  const { files, loading, error } = fileList;

  const icons = {
    File: <GetApp color='primary' className={classes.icon} />,
    Image: <Image color='primary' className={classes.icon} />,
    PDF: <PictureAsPdf color='primary' className={classes.icon} />,
    Folder: <Folder color='primary' className={classes.icon} />,
    FolderOpen: <FolderOpen color='primary' className={classes.icon} />,
    Loading: <Cached color='primary' style={{ marginBottom: 'auto' }} />,
  };

  const sortedDocs =
    files && files.docs.sort((a, b) => (a.Key > b.Key ? 1 : -1));
  // console.log(files);

  const handleFileClick = (e) => {
    const link = document.createElement('a');
    link.href = e.signedUrl;
    link.setAttribute('target', `_`);
    link.setAttribute('rel', `noopener noreferrer`);
    document.body.appendChild(link);
    link.click();
  };

  // console.log(sortedDocs);

  return (
    <>
      {error && <Message severity='error'>{error}</Message>}
      <Card className={classes.card}>
        <CardContent>
          {loading ? (
            <Loader />
          ) : (
            sortedDocs && (
              <div className='library-docs'>
                <FileBrowser
                  files={sortedDocs && sortedDocs}
                  icons={icons}
                  detailRenderer={() => null}
                  onSelectFile={(e) => handleFileClick(e)}
                />
              </div>
            )
          )}
        </CardContent>
      </Card>
    </>
  );
};

export { LibraryDocs };
